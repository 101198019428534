import './PxStyle.css';

const PxSidebar = (props) => {
    return (
        <aside className="left-sidebar">

            {/*
            sidebar scroll
            */}
            <div className="scroll-sidebar">
                <nav className="sidebar-nav">
                    <ul>
                        {props.children}
                    </ul>
                </nav>
            </div>

            {/*
            overlay on mobile view when sidebar is visible   
            */}
            <div className='overlay-mask'>

            </div>
        </aside>
    );
};

export default PxSidebar;