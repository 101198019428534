import React, { createContext, useState, useEffect } from 'react';
import { authenticateUser } from '../factory/AuthenticationFactory';
import { authenticateAzureUser } from '../factory/AzureAuthFactory';
import { getUserData } from '../factory/UserFactory';
import { getClientConfigData, getProperties, getDashboardConfigData } from '../factory/ClientConfigFactory';
import { fetchMedia } from "../factory/MediaFactory";
import * as msal from "@azure/msal-browser";
import * as msalConfigProvider from "../provider/MSALConfigProvider";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const UserContext = createContext({});
export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem("user");
        const initUser = JSON.parse(savedUser);
        return initUser || null;
    });

    const [authorization, setAuthorization] = useState(() => {
        const savedAuthorization = localStorage.getItem("authorization");
        const initAuthorization = JSON.parse(savedAuthorization);
        return initAuthorization || {};
    });
    const publicClientApplication = new msal.PublicClientApplication(msalConfigProvider.msalConfig);
    const [azureAuth, setAzureAuth] = useState(localStorage.getItem("azure"));

    const [properties, setProperties] = useState();
    const [clientConfig, setClientConfig] = useState();

    const [error, setError] = useState(null);
    const [dashboardConfig, setDashboardConfig] = useState([]);
    const MySwal = withReactContent(Swal);

    const userNotFoundAlert = MySwal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false
    });

    const [fetchingUser, setFetchingUser] = useState(false);

    useEffect(() => {
        const fetchProperties = async () => {
            const _prop = await getProperties();

            const properties = JSON.parse(_prop);
            setProperties(properties);

            const fetchClientConfig = async () => {
                const _clientConfig = await getClientConfigData(properties.clientId);
                if (_clientConfig?.invoiceConfig) {
                    _clientConfig.invoiceConfig = JSON.parse(_clientConfig?.invoiceConfig);
                }
                if (_clientConfig?.ticketConfig) {
                    _clientConfig.ticketConfig = JSON.parse(_clientConfig?.ticketConfig);
                }
                setClientConfig(_clientConfig);
            };
            fetchClientConfig()
                .catch(console.error);

            const fetchDashboardConfig = async () => {
                const _dashboardConfig = await getDashboardConfigData(properties.clientId);
                setDashboardConfig(_dashboardConfig);
            };
            fetchDashboardConfig()
                .catch(console.error);

        };
        fetchProperties()
            .catch(console.error);
    }, []);

    useEffect(() => {
        publicClientApplication.handleRedirectPromise().then((res) => handleResponse(res)).catch(console.error);
    }, []);

    // base64 String of the users avatar image
    const [avatar, setAvatar] = useState(null);

    // NOTE: authorization gets stored in local storage!
    useEffect(() => {
        if (authorization != null) {
            localStorage.setItem("authorization", JSON.stringify(authorization));
        }
    }, [authorization]);
    useEffect(() => {
        if (user != null) {
            localStorage.setItem("user", JSON.stringify(user));
        }
    }, [user]);

    const setAvatarImage = (id) => {
        fetchMedia(authorization?.token, id, (r) => {
            if (r?.data !== undefined && r?.data !== null) {
                setAvatar(r);
            }
        });
    };

    const openMSALPopup = () => {
        publicClientApplication.loginRedirect(msalConfigProvider.loginRequest);
    };

    const handleResponse = async (response) => {
        if (response !== null) {
            // Display signed-in user content, call API, etc.
            localStorage.setItem("azure", JSON.stringify(response));
            await azureAuthorization(response.account.username, response.idToken);
            setAzureAuth(response);
        } else {
            const currentAccounts = publicClientApplication.getAllAccounts();
            if (currentAccounts.length === 0) {
                publicClientApplication.loginRedirect(msalConfigProvider.loginRequest);
            }
        }
    };

    const azureAuthorization = async (azureIdentifier, accessToken) => {
        setFetchingUser(true);
        let token = await authenticateAzureUser(azureIdentifier, accessToken);
        if (token == null) {
            setAuthorization({
                valid: false,
            });
            userNotFoundAlert.fire({
                icon: 'error',
                title: clientConfig?.loginAlertTitle ? clientConfig?.loginAlertTitle : 'Keine Benutzerdaten gefunden',
                text: clientConfig?.loginAlertText ? clientConfig?.loginAlertText : 'Bitte wenden Sie sich an Ihren Betreuer',
                confirmButtonText: "Verstanden",
            }).then((res) => {
                if (res?.isConfirmed) {
                    doLogout();
                }
            });
            setFetchingUser(false);
            return;
        }
        setAuthorization({
            "token": token
        });

        let user = await getUserData(token);
        if(user) {
            setUser(user);
            localStorage.setItem("user", JSON.stringify(user));
        }
        setUser(user);

        // fetch avatar from backend
        if (user.avatarId !== undefined) {
            setAvatarImage(user.avatarId);
        }
    };

    const clearData = () => {
        // first we clear the local storage
        localStorage.clear();

        setAuthorization(null);
        setUser(null);
        setAvatar(null);
        setError(null);
        setAzureAuth(null);
    };

    const doLogout = () => {
        clearData();

        // logout azure
        if (clientConfig?.authMethod.includes('azure')) {
            publicClientApplication.logoutRedirect();
        }
    };

    return (
        <UserContext.Provider
            value={{
                clientConfig,
                dashboardConfig,
                user,
                authorization,
                azureAuth,
                error,
                setError,
                fetchingUser,

                // data is mocked!!
                authorize: async (username, password) => {
                    let token = await authenticateUser(username, password);

                    // when no token is received..
                    if (token == null) {
                        setAuthorization({
                            valid: false,
                        });
                        return;
                    }
                    setAuthorization({
                        token: token
                    });

                    let user = await getUserData(token);
                    setUser(user);

                    // fetch avatar from backend
                    if (user.avatarId !== undefined) {
                        setAvatarImage(user.avatarId);
                    }
                },
                fetchUser: async () => {
                    let user = await getUserData(authorization.token);
                    setUser(user);
                },
                logout: doLogout,
                getAvatar: () => {
                    if (avatar == null) {
                        setAvatarImage(user.avatarId);
                    }
                    return avatar;
                },
                initAzure: openMSALPopup,
                clearData,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const AuthContextConsumer = UserContext.Consumer;
