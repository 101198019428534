import $ from 'jquery';
var Timeline = {};
(function() {
	
	var fadeinRegister = function(el, onClickUrl) {

		var $p  = $(el);
		var totalHeight = 0;
		
		var $ps = $p.children();
		
		// measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
		$ps.each(function() {
			var h = $(this).outerHeight();
			if(h)
			totalHeight += h;
		});
		
		$p.data('fadeinRegisterHref', onClickUrl);
		
		if(totalHeight > 200) {
			if(!$p.data('fadeinClick')) {
				$p.addClass('fadeout');
				$p.data('fadeinClick', true);
				$p.on('click', function(event) {
					_fadein(event, el);
				});
			}
		} else {
			$p.removeClass('fadeout');
		}
	}
	var fadeinRecalc = function(el) {
		var $p  = $(el);
		fadeinRegister(el, $p.data('fadeinRegisterHref'));
	}
	var _fadein = function(event, el) {
		
		var totalHeight = 0
		
		var target = event.target;
		
		// nur wenn wirklich das element geklickt wurde
		if($(target).get(0) === $(el).get(0)) {
			
			var $p  = $(el);
			var $ps = $p.children();
			
			// measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
			$ps.each(function() {
				totalHeight += $(this).outerHeight();
			});
			
			$p
			.css({
				// Set height to prevent instant jumpdown when max height is removed
				"height": $p.height(),
				"max-height": 9999
			})
			.animate({
				"height": totalHeight
			}, 250);
			
			$p.removeClass('fadeout');
			
			// prevent jump-down
			return false;
		}
	};
	
	Timeline.fadeinRegister = fadeinRegister;
	Timeline.fadeinRecalc = fadeinRecalc;
})();

export default Timeline;