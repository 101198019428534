const PxGridRow = (props) => {
	
    const rowClassName = () => {
        return "row" + (props.className ? ' ' + props.className : '');
    }
	
    return (
       <div className={rowClassName()} style={props.style}>
           {props.children}
       </div>
    );
};

export default PxGridRow;