import './PxStyle.css';

const PxContent = (props) => {
    return (
        <div className="page-wrapper" style={{...props.style, display: "block"}}>
            <div className="container-fluid" style={props.styleContainer}>
                {props.children}
            </div>
        </div>
    );
};

export default PxContent;