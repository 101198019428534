import MaterialIcon from "./MaterialIcon";

const UserSettingsIcon = (props) => {
    return (
        <MaterialIcon icon="manage_accounts" 
            color={props.color} 
            outlined={props.outlined}
            round={props.round}
            sharp={props.sharp} 
            style={props.style}
        />
    );
};

export default UserSettingsIcon;