import React, {createContext, useState, useContext} from 'react';
import {UserContext} from "./UserContextProvider";
import {fetchTickets} from "../factory/TicketFactory";
import {fetchMedia} from "../factory/MediaFactory";

export const TicketSearchContext = createContext({});
export const TicketSearchContextProvider = ({children}) => {
    const [tickets, setTickets] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {authorization, user} = useContext(UserContext);

    const handleResponse = (jsonResponse) => {
        setTickets(jsonResponse.tickets);
        setLoaded(true);
    }

    return (
        <TicketSearchContext.Provider
            value={{
                tickets,

                fetchTicketAvatar: (id, f) => {
                    fetchMedia(authorization?.token, id, f);
                },
                fetchTicketList: async (mapping= undefined, parentId= undefined) => {
                    // we first clear the current list of tickets
                    setTickets([]);
                    fetchTickets(authorization?.token, mapping, parentId, user.showTickets, handleResponse);
                },
                fetchTicket:  (id, onResult) => {
                    if(!loaded) {
                        fetchTickets(authorization?.token, undefined, undefined, user.showTickets, (r) => {
                            setTickets(r.tickets)
                            setLoaded(true);
                            let ticket = r.tickets.find(t => t.id === parseInt(id));
                            onResult(ticket);
                        });
                    } else {
                        let ticket = tickets.find(t => t.id === parseInt(id));
                        onResult(ticket);
                    }
                },
            }}
        >
            {children}
        </TicketSearchContext.Provider>
    );
}