import MaterialIcon from "./MaterialIcon";

const FilterIcon= (props) => {
    return (
        <MaterialIcon icon="filter_alt" 
            color={props.color} 
            outlined={props.outlined}
            round={props.round}
            sharp={props.sharp} 
            style={props.style}
        />
    );
}

export default FilterIcon;