import ArrowDownIcon from "../icons/ArrowDownIcon";
import {
    Link
} from "react-router-dom";
import UserSettingsIcon from "../icons/UserSettingsIcon";
import LogoutIcon from "../icons/LogoutIcon";

const PxUserMenue = (props) => {

    const handleLogout = () => {
        if(props.logout !== undefined) {
            props.logout();
        }
    }

    return (
        <li className="dropdown">
            <a className="dropdown-toggle waves-effect waves-dark" data-toggle="dropdown">
                <span style={{marginTop: '-6px'}} >
                    {props.icon}
                </span>
                {props.username}
                <ArrowDownIcon />
            </a>
            <div className="dropdown-menu-right user-dd dropdown-menu">
                <Link className="sidebar-link  dropdown-item" to='/user'>
                    <span className="px-menue-item-icon">
                        <UserSettingsIcon outlined />
                    </span>
                    Benutzer
                </Link>
                <Link to='/' className="dropdown-item" onClick={handleLogout}>
                    <span className="px-menue-item-icon">
                        <LogoutIcon outlined color="#c32323"/>
                    </span>
                    Abmelden
                </Link>
            </div>
        </li>
    );
};

export default PxUserMenue;