import MaterialIcon from "./MaterialIcon";

const RefreshIcon = (props) => {
    return (
        <MaterialIcon icon="autorenew"
                      color={props.color}
                      outlined={props.outlined}
                      round={props.round}
                      sharp={props.sharp}
                      style={props.style}
        />
    );
};

export default RefreshIcon;