import {createAuthHeaders, createRequest, httpMethods, requestPublicUrl} from "./ApiUtils";

const endpoint = "invoices";

export const fetchInvoices = (token, typeCode, from, to, open, showInvoices, r) => {
   if(showInvoices) {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let url = requestPublicUrl(endpoint + '/list');

    if(typeCode !== undefined) {
        url = url + `?typeCode=${typeCode}`;
    }

    if(from !== undefined) {
        url = url + `&from=${from}`;
    }

    if(to !== undefined) {
        url = url + `&to=${to}`;
    }

    if(open !== undefined) {
        url = url + `&open=${open}`;
    }

    fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => r(JSON.parse(result)))
	.catch(e => {
		console.log(e);
	});
   } else {
    r([]);
   }
}

export const printInvoice = (token, objectId, fileName, endpointUrl, r) => {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let url = requestPublicUrl(endpoint + '/print');

    if(objectId !== undefined && endpointUrl !== undefined) {
        url = url + `?objectId=${objectId}` + '&' + `endpointUrl=${endpointUrl}`;
    }
    fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => r(fileName, JSON.parse(result)))
	.catch(e => {
		console.log(e);
	});
}
