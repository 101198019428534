import PxFormGroup from "./PxFormGroup";

const PxFormGroupInfo = (props) => {

    const defaultValueClass = "form-control-static";
    const determinValueClass = () => {
        if(props.valueLeft) {
            return defaultValueClass + " text-left";
        }
        if(props.valueRight) {
            return defaultValueClass + " text-right";
        }
        return defaultValueClass;
    };

    const renderValue = () => {
        if(props.value === undefined || props.value === null) {
            let altText = props.altText !== undefined? props.altText: "-";
            return altText;
        }
        return props.value;
    }

    return (
        <PxFormGroup
            left={props.labelLeft}
            right={props.labelRight}
            label={props.label}
        >
            <div className="col-md-8 px-form-vavlue">
                <p className={determinValueClass()}>
                    {renderValue()}
                </p>
            </div>
        </PxFormGroup>
    );
};
export default PxFormGroupInfo;