import React, {createContext, useState, useEffect, useContext} from 'react';
import {UserContext} from "./UserContextProvider";
import {fetchInvoices} from "../factory/InvoiceFactory";

const TYPE_CODE = "RE";

export const InvoiceSearchContext = createContext({});
export const InvoiceSearchContextProvider = ({children}) => {
    const [invoices, setInvoices] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {authorization, user} = useContext(UserContext);

    const handleResponse = (jsonResponse) => {
        setInvoices(jsonResponse.invoices);
        setLoaded(true);
    }

    useEffect(() => {
        fetchInvoices(authorization?.token, TYPE_CODE, undefined, undefined, undefined, user.showInvoices, handleResponse);
    }, []);

    return (
        <InvoiceSearchContext.Provider 
            value={{
                invoices,
                loaded,
                fetchInvoices: async (from= undefined, to=undefined, open=undefined, showInvoices =false) => {
                    // we first clear the current list of tickets
                    setLoaded(false);
                    setInvoices([]);
                    fetchInvoices(authorization?.token, TYPE_CODE, from, to, open, user.showInvoices, handleResponse);
                }
            }}

        >
            {children}
        </InvoiceSearchContext.Provider>
    )
};