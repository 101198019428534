import $ from 'jquery';

var TimelineMail = {};
(function() {
	
	var dropzone = function(instanceId) {
		
        var $fileInput = $(instanceId+' label');
        var $droparea = $fileInput;
        
		$fileInput.on('dragenter', function () {
            $droparea.addClass('dragover');

        });
        $fileInput.on('dragleave blur', function () {
            $droparea.removeClass('dragover');
        });
	}
	
	
	const handleImageUpload = (event, url) => {
		const files = event.target.files
		const formData = new FormData()
		
		for (let i=0; i<files.length; i++) {
			formData.append(files[i].name, files[i])
		}
		
		fetch(url, {
			method: 'POST',
			body: formData
		})
		.then(data => {
			data.text().then(function (text) {
				eval(text);
			});
		})
		.catch(error => {
			console.error(error)
		})
		
		// sonst bleibt dragover class irgendwie drin...
        var $fileInput = $(event.target).parent().removeClass('dragover');
	}

	const toggleAddresses = (event, el) => {
		
		el = $(el).closest('.angle');
		
		var isOpen = $(el).is('.down');
		var open = !isOpen;
		
		if(open) {
			$(el).removeClass('up').addClass('down');
			$(el).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
		} else {
			$(el).removeClass('down').addClass('up');
			$(el).find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
		}
		
		var fields = $(el).parents('.fields');
		var cc = fields.find('.cc');
		var bcc = fields.find('.bcc');
		var proposals = fields.find('.proposals');
		
		handleInput(open, cc, e => { return e.find('input').val() });
		handleInput(open, bcc, e => { return e.find('input').val() });
		handleInput(open, proposals, e => { return false });
		
	}
	
	function handleInput(open, el, func) {
		if(el) {
			if(open) {
				el.removeClass('hide');
			} else {
				if(func(el)) {
					el.removeClass('hide');
				} else {
					el.addClass('hide');
				}
			}
		}
	}

	function quickAddr(el, inputClass) {
		if(el) {
			el = $(el);
			var addr = el.parents('.prop').attr('px-quickaddr');
			var fields = el.parents('.fields');
			var input = fields.find(inputClass).find('input');
			
			if(el.is('.active')) {
				removeMailAddress(input, addr);
				el.removeClass('active');
			} else {
				addMailAddress(input, addr);
				el.addClass('active');
			}
		}
	}
	function addMailAddress(input, addr) {
		if(input) {
			var str = $(input).val();
			str += "; "+addr+";";
            str = mailAddressCleanup(str);
			$(input).val(str).change();
		}
	}
	function removeMailAddress(input, addr) {
		if(input) {
			var str = $(input).val();
			var str = str.replace(addr, ""); 
			str = mailAddressCleanup(str);
			$(input).val(str).change();
		}
	}
	function mailAddressCleanup(str) {
		// doppelte ;; entfernen
		str = str.replace(/[ ;]{2,}/, '; ')
		// führendes ; entfernen
		str = str.replace(/(?:^\s*;)/, "");
		str = str.trim();
		
		return str;	
	}
	
	function onChange(url, comment) {
		console.log(comment);
		//px_post(url, null, JSON.stringify({'body': comment}));
	}
	
	TimelineMail.dropzone = dropzone;
	
	TimelineMail.handleImageUpload = handleImageUpload;
	TimelineMail.toggleAddresses = toggleAddresses;
	
	TimelineMail.quickAddr = quickAddr;
})();

export default TimelineMail;