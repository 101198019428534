import {createAuthHeaders, createRequest, httpMethods, requestPublicUrl} from "./ApiUtils";

const ticketEndpoint = "tickets";
const sendMailEndpoint 	= "tickets/send";

export const fetchTickets = (token, mapping, parentId, showTickets, r) => {
   if(showTickets) {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let url = requestPublicUrl(ticketEndpoint);
    
    if(mapping !== undefined) {
        url = url + `?mapping=${mapping}`;
    }
    if(parentId !== undefined) {
        if(url.includes('?')){
            url = url + `&parentId=${parentId}`;
        } else {
            url = url + `?parentId=${parentId}`;
        }
    }
    fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => r(JSON.parse(result)))
	.catch(e => {
		console.log(e);
	});
   } else {
    r([]);
   }
}

export const sendEmail = (token, clientId, ticketId, mailModel, r) => {
    let headers = createAuthHeaders(token);
    headers.append("Content-Type", "application/json");

    let body = JSON.stringify(mailModel)
    let requestOptions = createRequest(httpMethods.POST, headers, body);
  
    let url = requestPublicUrl(sendMailEndpoint);
    url = url + `?objectId=${ticketId}`;
    url = url + `&clientId=${clientId}`;
  
    let response;
  
    fetch(url, requestOptions)
    .then(response => {
        if(!response.ok) throw new Error(response.status);
        else return response.text();
    })
    .then(result => 
       r(JSON.parse(result))
    ).catch(e => {
      console.log(e);
      r(null);
    });
    return response? response.resp : null;
  }