const PxButton = (props) => {

    const btnClass = () => {
        if(props.btn !== undefined) {
            return "btn " + props.btn;
        }
        return "btn btn-info"
    }

    return (
        <button onClick={props.onClick} className={btnClass()} style={props.style}>
            {props.children}
        </button>
    )
}
export default PxButton;