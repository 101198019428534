import './PxStyle.css';

const PxSidebarGroup = (props) => {
    return (
        <>
        <li className="nav-small-cap px-menue-title"><span className="hide-menu">{props.title}</span></li>
        {props.children}
        </>
    );
};

export default PxSidebarGroup;