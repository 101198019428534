import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import PxGridCard from "../../../components/pxcore/grid/PxGridCard";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import PxFormGroupInfo from "../../../components/pxcore/form/PxFormGroupInfo";
import PxFormInfo from "../../../components/pxcore/form/PxFormInfo";

const UserAddressView = (props) => {
    const renderTitle = () => {
        return (
            <>
                Benutzerinformationen
            </>
        );
    }

    return (
        <PxGridCard title={renderTitle()} large noflex cardBodyClass="">
            <div style={{ padding: '1rem' }}>
                {props.user.address.isFirm ? (
                    <>
                        <PxGridRow>
                            <PxGridColumn fullscreen>
                                <PxFormGroupInfo
                                    label="Firma"
                                    value={props.user.address.firm}
                                />
                            </PxGridColumn>
                        </PxGridRow>
                    </>
                ) : (
                    <>
                        <PxGridRow>
                            <PxGridColumn fullscreen>
                                <PxFormGroupInfo
                                    label="Vorname"
                                    value={props.user.address.firstName}
                                />
                            </PxGridColumn>
                        </PxGridRow>
                        <PxGridRow>
                            <PxGridColumn fullscreen>
                                <PxFormGroupInfo
                                    label="Nachname"
                                    value={props.user.address.lastName}
                                />
                            </PxGridColumn>
                        </PxGridRow>
                    </>
                )}
                {props.user.address.street ? <PxFormInfo label="Straße" value={props.user.address.street}/> : <></>}
                {props.user.address.zip ? <PxFormInfo label="PLZ" value={props.user.address.zip}/> : <></>}
                {props.user.address.city ? <PxFormInfo label="Stadt" value={props.user.address.city}/> : <></>}
                {props.user.address.country ? <PxFormInfo label="Land" value={props.user.address.country}/> : <></>}
            </div>
        </PxGridCard>
    );
};

export default UserAddressView;