const PxGridColumn = (props) => {

    // determin the col size class
    // the medium size col is returned on default
    const gridSize = () => {
        if(props.small) {
            return "px-col col-lg-2";
        }

        if(props.medium) {
            return "px-col col-lg-3 col-md-6";
        }
        
        if(props.large) {
            return "px-col col-lg-4 col-md-12"; 
        }

        if(props.xlarge) {
            return "px-col col-lg-8"; 
        }

        if(props.fullscreen) {
            return "px-col col-md-12"; 
        }
        
        if(props.half) {
            return "px-col col-md-6"; 
        }
        
        if(props.none) {
            return ""; 
        }

        return "px-col col-lg-4 col-md-12";
    }

    return (
       <div className={gridSize() + (props?.className ? ' ' + props?.className : '')} >
           {props.children}
       </div>
    );
};

export default PxGridColumn;