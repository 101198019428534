import PxGridColumn from "../../pxcore/grid/PxGridColumn";
import PxCard from "../../pxcore/PxCard";
import LocationIcon from "../../icons/LocationIcon";
import MailIcon from "../../icons/MailIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import {BeatLoader} from "react-spinners";
import React from "react";
import { UserContext } from "../../../context/UserContextProvider";
import {useContext} from 'react';

const PersonAddressCard = (props) => {
    const {clientConfig} = useContext(UserContext);
	const backgroundColor = clientConfig ? clientConfig.colorCode : "#b5a47f";
	const placeHolderImage = clientConfig ? 'data:image/jpeg;base64 ,' + clientConfig.placeHolderImage : null;

    const iconStyle = {
        fontSize:"18px",
        paddingRight:"10px",
		marginTop:"3px"
    }

    const renderText = (text) => {
        if(text === undefined) {
            let altText = props.altText !== undefined? props.altText: "-";
            return altText;
        }
        return text;
    }

    const renderNameText = () => {
        if(props.person.address.firm !== undefined) {
            return props.person.address.firm;
        }
        if(props.person.address.firstName === undefined || props.person.address.lastName === undefined) {
            let altText = props.altText !== undefined? props.altText: "-";
            return altText;
        }
        return `${props.person.address.firstName} ${props.person.address.lastName}`;
    }

    const renderAvatar = () => {
        if(props.hasAvatar) { //194 130
            if(props.avatar == null) {
                return (
                    <span style={{
                        marginBottom: '30px',
                        verticalAlign: 'middle',
                        borderRadius: "18%",
                        width: '80%', height:'60%'
                    }}>
                    <BeatLoader color="#86bacf" loading={true} size={5} speedMultiplier={0.8}/>
                    </span>
                )
            }
            return (
                <img className="avatar-image" src={`data:${props.avatar?.type};base64,${props.avatar?.data}`} alt="Logo" style={{width: '80%', height:'60%', objectFit:'contain' ,marginBottom: '30px'}}/>
            )
        }
        return <img  src={placeHolderImage} alt="Logo" style={{width: '80%', height:'60%', marginBottom: '30px'}} />;
    }

    const renderAddress = (city, country) => {
        if(city === undefined || city == null) {
            let altText = props.altText !== undefined ? props.altText : "-";
            return altText;
        }
        if(country === undefined || country == null) {
            return city;
        }
        return `${city}, ${country}`;
    }

    const renderUserName = (username) => {
        return username ? username.substring(0, 7) : '';
    }

    return (
        <PxGridColumn medium >
            <PxCard styleCard={{marginBottom:'0'}}>
                <div style={{textAlign:'center'}}>
                    {renderAvatar()}
                    <span>
                        <h4>
                        <span className="round" style={{backgroundColor: backgroundColor, marginRight:"5px", fontSize:"0.8rem"}}>
                            {renderUserName(props.person?.username)}
                        </span>
                            {renderNameText()}
                        </h4>
                    </span>
                </div>
            </PxCard>

            <PxCard styleCard={{backgroundColor:'#e5e5e5'}} cardBodyClass="" >
                <div style={{marginLeft:'23%'}}>
                    <p style={{display:'flex'}}><LocationIcon style={iconStyle} />{renderAddress(props.person.address.city, props.person.address.country)}</p>
                    <p style={{display:'flex'}}><MailIcon style={iconStyle} outlined/>{renderText(props.person.contact.email)}</p>
                    <p style={{display:'flex'}}><PhoneIcon style={iconStyle} outlined/>{renderText(props.person.contact.phone)}</p>
                </div>
            </PxCard>
        </PxGridColumn>
    );
}

export default PersonAddressCard;