const PxCard = (props) => {

    const renderHeadline = () => {
        if(props.title) {
            return true;
        }
        return false;
    }

    const determinClass = () => {
        if(props.noflex) {
            return "no-block" + (props.cardBodyClass != null ? " " + props.cardBodyClass : "");
        }
        return "d-flex no-block" + (props.cardBodyClass != null ? " " + props.cardBodyClass : "");
    }

    return (
        <div className="card" style={props.styleCard} onClick={props.onClick}>
            <div className="card-body">
                {renderHeadline() ? (
                    <h5 className="card-title">{props.title}</h5>
                    ) : null
                }
                <div className={determinClass()} style={props.styleBody}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PxCard;