import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import { BeatLoader } from "react-spinners";
import { UserContext } from "../../../context/UserContextProvider";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { apiConstants } from "../../../factory/ApiUtils";

const ChatScreen = () => {
    const [chatLoaded, setChatLoaded] = useState(false);
    const { authorization, user } = useContext(UserContext);

    useEffect(() => {
        if (!chatLoaded) {
            window.initGlobalChat(user, authorization.token, apiConstants.chatAppBaseUrl, apiConstants.chatCookieDomain);
            setChatLoaded(true);
        }
    });

    return (
        <>
            <PxGridRow>
                <div className="col-md-6 align-self-center" style={{ paddingBottom: "20px" }}>
                    <h3 className="page-title">Chat</h3>
                    <span lcass="breadcrumb-item">KUNDENPORTAL / </span>
                    <span className="breadcrumb-item active"> Chat</span>
                </div>
            </PxGridRow>

            <PxGridRow style={{flex:'1 1 auto'}}>
                <PxGridColumn fullscreen>
                    <div className="card chat-container px-chat" id="px-chat" style={{ bottom: "0" }}>
                        <div className="card-body">
                            <div style={{ textAlign: "center", paddingTop: "25%" }}>
                                <BeatLoader color="#86bacf" loading={true} size={20} speedMultiplier={0.6} />
                            </div>
                        </div>
                    </div>
                </PxGridColumn>
            </PxGridRow>
        </>
    )
};
export default ChatScreen;