import PxGridCard from "../../pxcore/grid/PxGridCard";
import FilterLink from "../filter/FilterLink";
import MaterialIcon from "../../icons/MaterialIcon";
import React from "react";


const TotalNumberDiagram = (props, config, onClick) => {

    const wConfig = JSON.parse(config.config);

    const hasFilter = () => {
        if(props.filters !== undefined) {
            return true
        }
        return false;
    }

    return (
        <>
            <PxGridCard title={config.title} small cardBodyClass="total_number px-dash-icon" onClick={onClick}>
                <div className="align-self-center no-shrink">
                    <span className="round" style={{backgroundColor:wConfig.color}}>
                        <span style={{paddingTop:"13px",paddingLeft:"13px", display:"flex"}}>
                            {wConfig.icon? <MaterialIcon icon={wConfig.icon} outlined color="white"/> : <></>}
                        </span>
                    </span>
                </div>
                <div className="align-self-center no-shrink">
                    <h2 className="m-b-0">{props.data?.count}</h2>
                </div>
                <h6 className="text-muted">
                    {hasFilter()? (
                        <FilterLink filter={props.filters}> {props.config.text} </FilterLink>
                    ): props.config.text}

                </h6>
            </PxGridCard>
        </>
    )
};

export default  TotalNumberDiagram;