
import {UserContext} from './context/UserContextProvider';
import {useContext} from 'react';
import { Helmet } from 'react-helmet';

const Title = () => {
    const {clientConfig} = useContext(UserContext);
    const clientConfigMetaTitle = clientConfig != null ? clientConfig.metaTitle : null;
    const clientConfigMetaIcon = clientConfig != null ? 'data:image/jpeg;base64 ,' + clientConfig.metaIcon : null;
    return (
        <Helmet>
            <title>{clientConfigMetaTitle}</title>
            <link rel="icon" type="image/png" href={clientConfigMetaIcon} sizes="16x16" />
            <link rel="apple-touch-icon" href={clientConfigMetaIcon} />
        </Helmet>
    )
}
export default Title;