import MaterialIcon from "./MaterialIcon";

const MailIcon = (props) => {
    return (
        <MaterialIcon icon="email" 
            color={props.color} 
            outlined={props.outlined}
            round={props.round}
            sharp={props.sharp} 
            style={props.style}
        />
    );
};

export default MailIcon;