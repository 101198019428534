import PxTable from "../../../components/pxcore/table/PxTable";
import './TicketTableStyle.css';

import {relativDate} from "../../../components/common/DateUtils";
import { useHistory } from "react-router-dom";
import StatusAvatar from "../../../components/common/StatusAvatar";
import { UserContext } from "../../../context/UserContextProvider";
import React, {useContext} from 'react';
import { getPrettyName } from "../../../factory/CommonUtils";
/*
const rowLinkStyle = {
  textDecoration: 'none',
  color: 'black',
  with: '100%'
}
*/

/**
 * 
 * @param {*} props 
 * @returns 
 */
const TicketTableView = (props) => {
    const {user, clientConfig} = useContext(UserContext);
    const history = useHistory();

    const getColumns = () => {
        if(props.columns !== undefined) {
            return props.columns;
        }
        return defaultColumns;
    }
/*
    const renderLinkCell = (rowData, cell) => {
      return (
        <Link to={`${rowData.destinationUrl}/${rowData.id}`} style={rowLinkStyle} >
          {cell}
        </Link>
      )
    }
*/
    const defaultColumns = [
        {
          title: "Ticket",
          field: "number",
          export: false,
          render: (rowData) => <StatusAvatar imageId={rowData.avatarId} showSpinner={rowData.avatarId !== undefined} avatar={rowData.fetchAvatar} color={rowData.statusExternalColor || 'grey'}/>
        },
        {
          title: "Nummer",
          field: "number",
          render: (rowData) => rowData.number
        },
        {
          title: "Name",
          field: "name"
        },
        {
            title: "Typ",
            field: "type",
        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => (<span className="badge" style={{backgroundColor: rowData.statusExternalColor || 'grey', color:"white", fontSize:"100%", minWidth:"100px"}} >{rowData.status}</span>)
        },
        {
          title: "Bearbeiter",
          field: "editor", 
        },
        {
            title: "Requestor",
            field: "requestor",
        },
        {
            title: "Erstellt am",
            field: "createDate",
            filtering: false,
            render: (rowData) => relativDate(rowData.createDate),
            customSort: (a, b) => a.createDate.valueOf() - b.createDate.valueOf()
          }
      ];
  const getExportFileName = () => {
    	var d = new Date();
        if(clientConfig?.ticketConfig?.exportText) {
            const name = getPrettyName(user);
            let header = clientConfig.ticketConfig.exportText + (name != null ? ' / ' + name : '') + '\n';
			return header + 'Datenexport vom: ' + d.toISOString().slice(0, 10) + ' ' + d.getUTCHours().toString().padStart(2, '0') + ':' + d.getUTCMinutes().toString().padStart(2, '0');
			
        }
    	return 'Ticket-Export_' + d.toISOString().slice(0, 10) + '-' + d.getUTCHours().toString().padStart(2, '0') + '-' + d.getUTCMinutes().toString().padStart(2, '0');
	}
	const getExportValue = (rowData, columnDef) => {
		
		switch(columnDef.field) {
			case 'number':
				return rowData.number;
			case 'name':
				return rowData.name;
			case 'type':
				return rowData.type;
			case 'status':
				return rowData.status;
			case 'editor':
				return rowData.editor;
			case 'requestor':
				return rowData.requestor;
			case 'createDate':
				return rowData.createDate.format('DD.MM.YYYY');
			default:
				return '';
		}
	}
 
    return (
        <PxTable
            tableType = 'ticket'
            refetchFunction={props.refetchFunction}
            title={props.title}
            simpleTitle={props.simpleTitle}
            data={props.tickets}
            exportFileName={getExportFileName()}
			getExportValue={getExportValue}
            exportPdf
            exportCsv
            columns={getColumns()}
            onRowClick={(event, rowData) => {
              if(history.location.pathname.includes('/tickets/')){
                history.push(`${rowData.id}`);
              } else {
                history.push(`${rowData.destinationUrl}/${rowData.id}`);
              }
            }}
            isLoaded={props.isLoaded}
        />
    );
}


export default TicketTableView;