import React, {useContext, useEffect, useState} from "react";
import {TicketSearchContext} from "../../../context/TicketSearchContext";
import {Link} from "react-router-dom";
import {toPrettyName} from "../../../components/common/PersonAddressUtil";
import moment from "moment/moment";
import TicketTableView from "./TicketTableView";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import {relativDate} from "../../../components/common/DateUtils";
import StatusAvatar from "../../../components/common/StatusAvatar";
import { useParams} from "react-router-dom";
import {fetchTickets} from "../../../factory/TicketFactory";
import {UserContext} from "../../../context/UserContextProvider";

const TicketTableCard = (props) => {
    const [ticketRows, setTicketRows] = useState([]);
    const { fetchTicketAvatar} = useContext(TicketSearchContext);
    const [_tickets, setTickets] = useState([]);
    let { mapping, ticketId} = useParams();
    const {authorization, clientConfig, user} = useContext(UserContext);
    const [loaded, setLoaded] = useState(false);

    const columns = [
        {
            title: "Ticket",
            field: "number",
            export: false,
            headerStyle: {width: "130px"},
            render: (rowData) => <StatusAvatar imageId={rowData.avatarId} showSpinner={rowData.avatarId !== undefined} avatar={rowData.fetchAvatar} color={rowData.statusExternalColor || 'grey'}/>
        },
        {
            title: "Nummer",
            field: "number",
            headerStyle: {width: "130px"},
        },
        {
            title: "Name",
            field: "name",
            headerStyle: {width: "130px"}
        },
        
        {
            title: "Typ",
            field: "type",
            headerStyle: {width: "130px"}
        },
        {
            title: "Status",
            field: "status",
            headerStyle: {width: "130px"},
            render: (rowData) => (<span className="badge" style={{backgroundColor: rowData.statusExternalColor || 'grey', color:"white", fontSize:"100%", minWidth:"100px"}} >{rowData.status}</span>),
        },
        {
            title: "Erstellt am",
            field: "createDate",
            filtering: false,
            render: (rowData) => rowData.createDateRow,
            customSort: (a, b) => a.createDate.valueOf() - b.createDate.valueOf()
        }
    ];
    if(props.width == undefined){
        columns.push({
            title: "Fallig bis",
            field: "dueDate",
            filtering: false,
            render: (rowData) => rowData.dueDateRow,
            customSort: (a, b) => a.dueDate.valueOf() - b.dueDate.valueOf()
        },
        {
            title: "Gelöst am",
            field: "solutionEnd",
            filtering: false,
            render: (rowData) => rowData.solutionEndRow,
            customSort: (a, b) => a.solutionEnd.valueOf() - b.solutionEnd.valueOf()
        });
    }

    const handleResponse = (jsonResponse) => {
        setTickets(jsonResponse.tickets);
        setLoaded(true);
    }

    useEffect(() => {
        handleRefetch();
    }, [props.ticket]);

    const handleRefetch = () => {
        if(mapping !== undefined && mapping !== 'tickets' && ticketId !== undefined){
            if(mapping === 'all') {
                fetchTickets(authorization?.token, undefined, ticketId, user?.showTickets, handleResponse);
            } else {
                fetchTickets(authorization?.token, mapping, ticketId, user?.showTickets, handleResponse);
            }
        }else if(mapping !== undefined && mapping !== 'tickets'){
            fetchTickets(authorization?.token, mapping, undefined, user?.showTickets, handleResponse);
        }else if(props.ticket !== undefined && props.childrenTicket !== undefined){
            setTickets(props.childrenTicket);
        } else {
            fetchTickets(authorization?.token, undefined, undefined, user?.showTickets, handleResponse);
        }
    }

    const getDestinationUrl = () => {
        if(mapping !== undefined && ticketId !== undefined){
            return mapping + '/' + ticketId;
        }else if(mapping !== undefined){
            return mapping;       
        }else if(props.ticket.type?.name === 'Epic'){
            return 'tickets';
        }
    }

    useEffect(() => {
        if(_tickets?.length > 0) {
            let rows = [];
            for (let i = 0; i < _tickets.length; i++) {
                let ticket = _tickets[i];

                let row = {
                    id: ticket.id,
                    avatarId : ticket.avatarId,
                    fetchAvatar: fetchTicketAvatar,    
                    destinationUrl: 'tickets',
                    number: ticket.number,
                    statusExternalColor: ticket.statusExternal?.color,
                    numberRow: (<Link to={`tickets/${ticket.id}`}><span
                        className="round ticket-link">{ticket.number}</span></Link>),
                    name: ticket.title,
                    status: ticket.statusExternal != null ? ticket.statusExternal.name : "",
                    type: ticket.type?.name,
                    statusRow: (<span className="badge" style={{
                        backgroundColor: ticket.statusExternal?.color || 'grey',
                        color: "white",
                        fontSize: "100%",
						minWidth:"100px"
                    }}>{ticket.statusExternal?.name}</span>),
                    editor: ticket.persons?.find(p => p.roles && p.roles.indexOf("Editor") !== -1) !== undefined ? toPrettyName(ticket.persons?.find(p => p.roles && p.roles.indexOf("Editor") !== -1).address) : "",
                    createDateRow: relativDate(ticket.createDate),
                    createDate: moment(ticket.createDate),
                    dueDateRow: ticket.dueDate.length !==0 ? relativDate(ticket.dueDate) : "",
                    dueDate: ticket.dueDate.length !==0 ? moment(ticket.dueDate) : "",
                    solutionEndRow: ticket.solutionEnd.length !==0 ? relativDate(ticket.solutionEnd) : "",
                    solutionEnd: ticket.solutionEnd.length !==0 ? moment(ticket.solutionEnd) : ""
                };

                rows.push(row);
            }

            setTicketRows(rows);
        }
    }, [_tickets]);

    return (
        <> { props.width ?
            <PxGridColumn xlarge>
                {<TicketTableView title="Aktuelle Tickets" simpleTitle columns={columns} refetchFunction={handleRefetch} tickets ={ticketRows} isLoaded={loaded} /> }
            </PxGridColumn>
        :
            <PxGridColumn fullscreen>
                {<TicketTableView title="Aktuelle Tickets" simpleTitle columns={columns} refetchFunction={handleRefetch} tickets ={ticketRows} isLoaded={loaded}/>}
            </PxGridColumn>
        }
        </>
    )
}
export default TicketTableCard;