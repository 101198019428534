import MaterialTable, { MTableBody } from "material-table";
import PxButton from "../PxButton";
import FilterIcon from "../../icons/FilterIcon";
import FilterOffIcon from "../../icons/FilterOffIcon";
import React, {useState, useContext} from 'react';
import { BeatLoader } from "react-spinners";
import RefreshIcon from "../../icons/RefreshIcon";
import { UserContext } from "../../../context/UserContextProvider";
import { font } from "../../../assets/fonts/fonts";

const jsPDF = typeof window !== "undefined" ? require("jspdf").jsPDF : null;

const PxTable = (props) => {
    const [filtering, setFiltering] = useState(false);
    const {clientConfig} = useContext(UserContext);
    const tableType = props.tableType;

    const backgroundColor = clientConfig ? clientConfig.colorCode : null;

    const displayFilter = () => {
        if(filtering) {
            setFiltering(false);
        } else {
            setFiltering(true);
        }
    }

    const renderTitle = () => {
        if(props.simpleTitle !== undefined && props.simpleTitle) {
            return (
                <h5 className="card-title">{props.title}</h5>
            )
        }

        return (
            <>
                {props.title ? <span style={{marginRight:"30px"}}>{props.title}</span>: <></>}
                <PxButton onClick={() => props.refetchFunction()} btn="btn-passive" style={{marginRight:"30px", marginBottom:"5px", marginTop:"5px"}} >
                    Aktualisieren
                    <RefreshIcon outlined color="white" style={{fontSize:"small", paddingLeft:"5px"}} />
                </PxButton>

                {renderAdditionalContent()}

                <PxButton onClick={displayFilter} style={{marginBottom:"5px", marginTop:"5px"}}>
                    FILTER
                    {!filtering?
                        <FilterIcon outlined color="white" style={{fontSize:"small", paddingLeft:"5px"}} />
                        :
                        <FilterOffIcon outlined color="white" style={{fontSize:"small", paddingLeft:"5px"}} />
                    }
                </PxButton>
                {props.extraHeaders ?  props.extraHeaders : ''}
            </>
        )
    }

    const renderAdditionalContent = () => {
        if(props.customFilter !== undefined) {
            return props.customFilter;
        }
        return <></>;
    }

    const emptyDataSourceMessage = () => {
        if(!props.isLoaded && props.data?.length == 0) {
            return <BeatLoader color="#86bacf" loading={true} size={20} speedMultiplier={0.8} />
        }
        return <>Keine Ergebnisse</>
    }
	
    /**
     * Setzt anzuzeigende Spalten und Zeilen von Rechnungen
     */
    const checkInvoiceData = (columns, data, invoiceConfig) => {
        if(invoiceConfig.exportColumns){
            columns = columns.filter(c => invoiceConfig.exportColumns.includes(c.field));
        }
        return { 'columns' : columns, 'data' : data};
    }

    /**
     * Setzt anzuzeigende Spalten und Zeilen von Tickets
     */
    const checkTicketData = (columns, data, ticketConfig) => {
        if(ticketConfig.exportColumns){
            columns = columns.filter(c => ticketConfig.exportColumns.includes(c.field));
        }
        return { 'columns' : columns, 'data' : data};
    }

    const showLogo = () => {
        console.log(clientConfig?.ticketConfig);
        if(clientConfig?.exportLogo) {
            if(tableType === 'invoice')
                return clientConfig?.invoiceConfig?.showLogo;
    
            if(tableType === 'ticket')
             return clientConfig?.ticketConfig?.showLogo;
        }
        return false;
    }

    const isInvoice = () => {
        return tableType === "invoice";
    }

    const isTicket = () => {
        return tableType === "ticket";
    }

    const handleExportPdf = (columns, data) => {
	    if (jsPDF !== null) {

            if(isInvoice() && clientConfig.invoiceConfig) {
                let invoiceData = checkInvoiceData(columns, data, clientConfig.invoiceConfig);
                columns = invoiceData.columns;
                data = invoiceData.data;
            } else if(isTicket() && clientConfig.ticketConfig) {
                let ticketData = checkTicketData(columns, data, clientConfig.ticketConfig);
                columns = ticketData.columns;
                data = ticketData.data;
            }

            data = data.map((rowData) =>
            columns.map((columnDef) => props.getExportValue ? props.getExportValue(rowData, columnDef) : rowData[columnDef.field])
            );

		  var columnStyles = new Array();
		  for(var i=0;i<columns.length; i++) {
			columnStyles[i] = columns[i].exportColumnStyle;
		  }

	      let content = {
	        startY: 70,
	        head: [columns.map((columnDef) => columnDef.title)],
	        body: data,
			headStyles: { fillColor: backgroundColor || [37, 43, 74] },
			didParseCell: (hookData) => {
		        if (hookData.section === 'head') {
					var exportHeadStyle = columns[hookData.column.dataKey]?.exportHeadStyle;
		            if(exportHeadStyle) {
		                Object.assign(hookData.cell.styles, exportHeadStyle);
		            }
		        }
		    },
			columnStyles: columnStyles
	      };
	
	      const unit = "pt";
	      const size = "A4";
	      const orientation = "landscape";
	      const doc = new jsPDF(orientation, unit, size);
          doc.addFileToVFS("WorkSans-normal.ttf", font);
          doc.addFont("WorkSans-normal.ttf", "WorkSans", "normal");
	      doc.setFontSize(15);
          doc.text(props.exportFileName || props.title, 40, 40);

          if(showLogo()) {
            let imgData = 'data:image/png;base64,'+ clientConfig?.exportLogo;
            doc.addImage(imgData, 'PNG', 620, 20, 200*0.9, 39*0.9);
          }

	      doc.autoTable(content);

          let pageCount = doc.getNumberOfPages();
          if(pageCount > 1) {
            for(i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
                doc.setFontSize(12);
                doc.text(pageCurrent + '/' + pageCount, doc.internal.pageSize.width - 10 - (pageCount.toString().length * 15), doc.internal.pageSize.height - 10);
              }
          }          

	      doc.save(
	        (props.exportFileName || props.title || "data") + ".pdf"
	      );
	    }
  };

    return (
        <MaterialTable
            title={renderTitle()}
            
            data={props.data}
            columns={props.columns}
            options={{ headerStyle: {fontWeight:"bold", whiteSpace:"noWrap"}, tableLayout:"fixed", search: true, paging: true, pageSize:10, pageSizeOptions: [10,50,100], 
				filtering: filtering, sorting: true, exportButton: {
	                csv: props.exportCsv,
	                pdf: props.exportPdf
	            }, exportPdf: handleExportPdf, exportFileName:props.exportFileName, exportAllData: true, draggable: false}}
            icons={{ Filter: () => <></> }}

            localization={{
                toolbar: {
                    addRemoveColumns: 'Spalten hinzufügen oder löschen',
		          	nRowsSelected: '{0} Zeile(n) ausgewählt',
		          	showColumnsTitle: 'Zeige Spalten',
		          	showColumnsAriaLabel: 'Zeige Spalten',
		          	exportTitle: 'Export',
		          	exportAriaLabel: 'Export',
		          	exportCSVName: 'Export als CSV',
		          	exportPDFName: 'Export als PDF',
		          	searchTooltip: 'Suche',
		          	searchPlaceholder: 'Suche'
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} von {count}',
					labelRowsSelect: 'Zeilen',
			        labelRowsPerPage: 'Zeilen pro Seite:',
			        firstAriaLabel: 'Erste Seite',
			        firstTooltip: 'Erste Seite',
			        previousAriaLabel: 'Vorherige Seite',
			        previousTooltip: 'Vorherige Seite',
			        nextAriaLabel: 'Nächste Seite',
			        nextTooltip: 'Nächste Seite',
			        lastAriaLabel: 'Letzte Seite',
			        lastTooltip: 'Letzte Seite'
                },
                body: {
                  emptyDataSourceMessage: (emptyDataSourceMessage())
                },
            }}

            onRowClick={props.onRowClick}
        />
    );
}

export default PxTable;

// icons={{ Filter: () => <FilterIcon outlined style={{fontSize:"small"}} /> }} //
// <BeatLoader color="#86bacf" loading={true} size={20} speedMultiplier={0.8} />