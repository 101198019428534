import { requestPublicUrl,  createAuthHeaders, createRequest, httpMethods} from "./ApiUtils";

const clientConfigEndpoint = "client/config";
const propertiesEndpoint = "client/properties"
const clientDashboardConfigEndpoint = "client/dashboard/config";

export const getClientConfigData = async (clientId) => {
  let headers = createAuthHeaders();
  let requestOptions = createRequest(httpMethods.GET, headers);

  let url = requestPublicUrl(clientConfigEndpoint);
  if(clientId !== undefined) {
    url = url + `?clientId=${clientId}`;
  }
  let clientConfig;
  await fetch(url, requestOptions)
  .then(response => response.text())
  .then(result => clientConfig = JSON.parse(result))
  .catch(error => (
      console.log('error', error)
      ));
  return clientConfig? clientConfig : null;
};

export const getProperties = async () => {
  let headers = createAuthHeaders();
  let requestOptions = createRequest(httpMethods.GET, headers);

  let url = requestPublicUrl(propertiesEndpoint);
  let properties;
  await fetch(url, requestOptions)
  .then(response => response.text())
  .then(result => properties = JSON.parse(result))
  .catch(error => (
      console.log('error', error)
      ));
  return properties? properties : null;
};


export const getDashboardConfigData = async (clientId) => {
  let headers = createAuthHeaders();
  let requestOptions = createRequest(httpMethods.GET, headers);

  let url = requestPublicUrl(clientDashboardConfigEndpoint);
  if(clientId !== undefined) {
    url = url + `?clientId=${clientId}`;
  }
  let dashboardConfig;
  await fetch(url, requestOptions)
  .then(response => response.text())
  .then(result => dashboardConfig = JSON.parse(result))
  .catch(error => (
      console.log('error', error)
      ));
  return dashboardConfig? dashboardConfig : null;
};