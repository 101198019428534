import PxGridRow from "../grid/PxGridRow";
import PxGridColumn from "../grid/PxGridColumn";
import PxFormGroupInfo from "./PxFormGroupInfo";


const PxFormInfo = (props) => {
    return (
        <PxGridRow>
            <PxGridColumn fullscreen>
                <PxFormGroupInfo
                    label={props.label}
                    value={props.value}
                />
            </PxGridColumn>
        </PxGridRow>
    );
};

export default PxFormInfo;