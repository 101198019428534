import React, { useEffect, useState } from "react";
import {getDashBoardData} from "../../../factory/DataReportFactory";
import {getComponentByName} from "./DashboardComponentFactory";
import FilterLink from "../filter/FilterLink";
import EmptyLoadingCard from "../diagramm/EmptyLoadingCard";

const DashBoardComponent = (props) => {
    const [dashboard, setDashboard] = useState(null);

    //let c = getComponentByName(data.config.component);
    const createDashBoard = async (data) => {
        setDashboard(data);
    }

    useEffect(() => {
        getDashBoardData(props.token, props.config.id, createDashBoard);
    }, [])


    if(dashboard == null) {
        return <EmptyLoadingCard />
    }

    /*if(dashboard.filter !== undefined) {
        return <FilterLink filter={dashboard.filter}> seas
                    <component config={dashboard.config} data={dashboard.data} />
                </FilterLink>
    }

    */
    return getComponentByName(dashboard.config.component)(dashboard, props.config, props.onClick);
}

export default  DashBoardComponent