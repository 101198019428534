import {
  HashRouter as Router
} from "react-router-dom";
import './components/pxcore/PxStyle.css'
import PxPortal from "./screens/PxPortal";
import Title from "./AppTitle";
import { UserContextProvider } from './context/UserContextProvider';

function App() {

  return (
    <div className="App">
      <UserContextProvider>
        <Router>
          <PxPortal/>
            <Title></Title>
        </Router>
      </UserContextProvider>
    </div>
  );
}

// "homepage": ".",
export default App;
