import './PxFormStyle.css';

const PxFormGroup = (props) => {
    
    const defaultFormGroupClass = "control-label col-md-4";

    // determins the exact form gorup class from from props
    // the text direction is either left or right
    const determinClass = () => {
        if(props.left) {
            return defaultFormGroupClass + " text-left";
        }
        if(props.right) {
            return defaultFormGroupClass + " text-right";
        }
        return defaultFormGroupClass + " text-left"
    }

    return (
        <div className="form-group row">
            <label className={determinClass()}>
                {props.label}
            </label>
            {props.children}
        </div>
    );
};

export default PxFormGroup;