import PxGridColumn from "./PxGridColumn";
import PxCard from "../PxCard";

const PxGridCard = (props) => {
    return (
        <PxGridColumn 
            small={props.small} 
            medium={props.medium} 
            large={props.large} 
            xlarge={props.xlarge}
            fullscreen={props.fullscreen}
        >
            <PxCard cardBodyClass={props.cardBodyClass} noflex={props.noflex} title={props.title} styleCard={props.styleCard} styleBody={props.styleBody} onClick={props.onClick}>
                {props.children}
            </PxCard>
        </PxGridColumn>
    );
};

export default PxGridCard;