import {requestPublicUrl,  createAuthHeaders, createRequest, httpMethods} from "./ApiUtils";

const userEndpoint = "user"

export const getUserData = async (token) => {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let user;
    await fetch(requestPublicUrl(userEndpoint), requestOptions)
    .then(response => response.text())
    .then(result => user = JSON.parse(result))
    .catch(error => (
        console.log('error', error)
        ));
    return user? user : null;
};