import EngineerIcon from "../../../components/icons/EngineerIcon";
import PersonsIcon from "../../../components/icons/PersonsIcon";
import React, { useEffect, useState } from "react";
import BulletPointCardList from "../../../components/common/BulletPointCardList";

const TicketPersonsCard = (props) => {
    const renderPersonTypeIcon = (editor) => {
        if(editor) {
            return (
                <span className="round" style={{backgroundColor:"rgb(202 200 211)"}}>
                    <span style={{paddingTop:"13px",paddingLeft:"13px", display:"flex"}}>
                        <EngineerIcon  color="white" outlined />
                    </span>
                </span>
            )
        }

        return (
            <span className="round" style={{backgroundColor:"rgb(96 100 114)"}}>
                <span style={{paddingTop:"13px",paddingLeft:"13px", display:"flex"}}>
                    <PersonsIcon  color="white" outlined />
                </span>
            </span>
        )
    }

    const personsFromTicket = () => {
        if(props.ticket === undefined) {
            return [];
        }
        let cards = [];
        props.ticket?.persons?.forEach( (p,i) => {
            let card = {};
            card.headline = p.address.firm? p.address.firm : p.address.firstName + " " + p.address.lastName;
            card.content = p.roles ? p.roles.map(item => item == 'Customer' ? 'Kunde' : item == 'Editor' ? 'Bearbeiter' : item == 'Observer' ? 'Beobachter' : item).join(', ') : '';
            card.footer = p.contact?.email;
            card.bullet = renderPersonTypeIcon(p.roles && p.roles.indexOf("Editor") !== -1);

            cards.push(card);
        }
        );

        return cards;
    }

    return (
        <BulletPointCardList title="Ansprechpartner" data={personsFromTicket()}/>
    );
}

export default TicketPersonsCard;