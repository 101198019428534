import { BeatLoader } from "react-spinners";
import React from "react";
import { useState, useEffect } from 'react';
import { UserContext } from "../../context/UserContextProvider";
import {useContext} from 'react';

const StatusAvatar = (props) => {
    const [avatar, setAvatar] = useState(null);
    const {clientConfig} = useContext(UserContext);
	const placeHolderImage = clientConfig ? 'data:image/jpeg;base64 ,' + clientConfig.placeHolderImage : null;
	

    /**
    * fetching ticket details from api
    */
    useEffect(() => {
        if(props.imageId !== undefined) {
            props.avatar(props.imageId, onAvatarFetched);
        }
    }, [props]);

    const onAvatarFetched = (a) => {
        if (a !== undefined && a!== null) {
            setAvatar(a);
        }
    }

    if (avatar !== null) {
        return (
            <img className="rounded-circle" src={`data:${avatar?.type};base64,${avatar?.data}`} alt="avatar"
                style={{ width: '50px', height: '50px', objectFit: 'cover', border: `2px solid ${props.color}` }} />
        );
    }

    if (props.showSpinner) {
        return (
            <span style={{
                width: '50px',
                height: '50px',
                display: 'inline-block',
                border: `2px solid ${props.color}`,
                borderRadius: "50%",
                textAlign: "center",
                paddingTop: "12px"
            }}>
                <BeatLoader color="#86bacf" loading={true} size={5} speedMultiplier={0.8} />
            </span>
        );
    }

    return (
        <div className="rounded-circle" style={{ display: 'flex', alignItems: 'center', width: '50px', height: '50px', objectFit: 'scale-down', border: `2px solid ${props.color}`, paddingLeft: '8px' }}>
            <img src={placeHolderImage} alt="avatar"
                style={{ width: '30px', height: '30px', objectFit: 'scale-down' }} />
        </div>
    )
}
export default StatusAvatar;