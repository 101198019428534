import PxMainFrame from "../components/pxcore/PxMainFrame";
import PxSidebar from "../components/pxcore/PxSidebar";
import PxSidebarGroup from "../components/pxcore/PxSidebarGroup";
import PxSidebarItem from "../components/pxcore/PxSidebarItem";
import DashBoardIcon from "../components/icons/DashBoardIcon";
import TicketIcon from "../components/icons/TicketIcon";
import InvoiceIcon from "../components/icons/InvoiceIcon";
import PxContent from "../components/pxcore/PxContent";
import DashboardScreen from "./view/dashboard/DashBoardScreen";
import HeaderView from "./view/main/HeaderView";
import TicketScreen from "./view/ticket/TicketScreen";
import InvoiceScreen from "./view/invoice/InvoiceSreen";
import { ErrorBoundary } from 'react-error-boundary'
import ChatScreen from "./view/chat/ChatScreen";
import { InvoiceSearchContextProvider } from "../context/InvoiceSearchContext";
import {
  Switch,
  Route
} from "react-router-dom";

import UserSettingsIcon from "../components/icons/UserSettingsIcon";
import UserScreen from "./view/user/UserScreen";
import { TicketSearchContextProvider } from "../context/TicketSearchContext";
import { useLocation } from 'react-router-dom';
import { UserContext } from "../context/UserContextProvider";
import { useContext } from "react";
import {fetchEntity} from "../factory/TimelineFactory";

const MainView = (props) => {
  const {user, clientConfig} = useContext(UserContext);

  const location = useLocation();

  return (
    <PxMainFrame>
      <HeaderView />

      <PxSidebar>
        <PxSidebarGroup title="Kundenportal">

          <PxSidebarItem
            title="Dashboard"
            icon={<DashBoardIcon color={location.pathname == '/' ? '#fcb514' : ''} />}
            href="/"
          />
          {user.showTickets ? 
            <PxSidebarItem
            title="Tickets"
            icon={<TicketIcon round color={location.pathname == '/tickets' ? '#fcb514' : ''} />}
            href="/tickets"
            /> : <></>}
          
          {user.showInvoices ? 
            <PxSidebarItem
            title="Rechnungen"
            icon={<InvoiceIcon outlined color={location.pathname == '/invoices' ? '#fcb514' : ''} />}
            href="/invoices"/> : <></>}


          <PxSidebarItem
            title="Benutzer"
            icon={<UserSettingsIcon outlined color={location.pathname == '/user' ? '#fcb514' : ''} />}
            href="/user"
          />

        </PxSidebarGroup>
      </PxSidebar>

      <PxContent styleContainer={{display:'flex', flexDirection:'column'}}>
        <Switch>
          <Route path="/tickets">
            <TicketSearchContextProvider>
              <TicketScreen />
            </TicketSearchContextProvider>
          </Route>

          <Route path="/invoices">
            <InvoiceSearchContextProvider>
              <InvoiceScreen />
            </InvoiceSearchContextProvider>
          </Route>

          <Route path="/user">
            <UserScreen />
          </Route>

          <Route path="/chat">
            <ChatScreen />
          </Route>

          <Route path="/">
            <TicketSearchContextProvider>
              <DashboardScreen />
            </TicketSearchContextProvider>
          </Route>

        </Switch>
      </PxContent>

    </PxMainFrame>
  );
};
export default MainView;