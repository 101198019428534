import PxHeader from "../../../components/pxcore/PxHeader";
import NotificationBellIcon from "../../../components/icons/NotificationBellIcon";
import DashBoardIcon from "../../../components/icons/DashBoardIcon";
import PxUserMenue from "../../../components/pxcore/PxUserMenue";
import { UserContext } from "../../../context/UserContextProvider";
import { useContext } from 'react';
import {
  Link
} from "react-router-dom";

/**
 * The default view of the header bar
 * icon={
              <img className="rounded-circle" src={Avatar} alt="Logo" style={{width: '2rem'}}/>
            }
 * @param {*} props 
 */
const HeaderView = (props) => {
  const { user, logout, clientConfig } = useContext(UserContext);
	const mainLogo = clientConfig ? 'data:image/jpeg;base64 ,' + clientConfig.mainImage : null;

  return (
    <PxHeader
      brand={<Link to='/' ><img style={{ width: '10rem' }} className="px-logo" src={mainLogo} alt="Logo" /></Link>}

      itemsRight={
        <>
          <Link to="/">
            <DashBoardIcon />
          </Link>

          <Link to="/chat">
            <NotificationBellIcon outlined />
          </Link>

          <PxUserMenue
            logout={logout}
            username={user.appName}
          />
        </>
      }
    />
  );
};

export default HeaderView;