import { useContext } from "react";
import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import { InvoiceSearchContext } from "../../../context/InvoiceSearchContext";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import InvoiceTableView from "./InvoiceTableView";

const InvoiceScreen = (props) => {
    const {invoices, loaded, fetchInvoices} = useContext(InvoiceSearchContext);


    return (
        <>
            <PxGridRow>
                <div className="col-md-6 align-self-center" style={{ paddingBottom: "20px" }}>
                    <h3 className="page-title">Rechnungen</h3>
                    <span lcass="breadcrumb-item">KUNDENPORTAL / </span>
                    <span className="breadcrumb-item active"> RECHNUNGEN</span>
                </div>
            </PxGridRow>

            <PxGridRow>
                <PxGridColumn fullscreen className="overview-invoice">
                    <InvoiceTableView isLoaded={loaded} invoices={invoices} refetchFunction={fetchInvoices} />
                </PxGridColumn>
            </PxGridRow>
        </>
    )
}
export default InvoiceScreen;