import {createAuthHeaders, createRequest, httpMethods, requestPublicUrl} from "./ApiUtils";

const dashBoardEndpoint = "dashboard"

export const getDashBoardData = async (token, mapping, e) => {
  let headers = createAuthHeaders(token);
  let requestOptions = createRequest(httpMethods.GET, headers);

  let url = requestPublicUrl(dashBoardEndpoint);
  url = url + `?mapping=${mapping}`;

  fetch(url, requestOptions)
      .then(response => response.text())
      .then(result => e(JSON.parse(result)))
	.catch(e => console.log(e));

  /*let data = {};
  if(mapping === "open_tickets") {
    data = {
      config:{
        title:"Offener Tickets",
        text:"In Bearbeitung",
        component: "totalNumberDiagram",
        color:"#252b4a",
        icon:"sell"
      },
      data:{
        count:"4"
      },
      filters:"2345",
    };
  }

  if(mapping === "waiting_tickets") {
    data = {
      config:{
        title:"Wartende Tickets",
        text:"Wartende Tickets",
        component: "totalNumberDiagram",
        color:"#fcb514",
      },
      data:{
        count:"6"
      },
      filters:"2345",
    };
  }

  if(mapping === "ticket_status_report") {
    data = {
      config:{
        title:"Ticket Übersicht",
        component: "donutPieChart",
        text:"(Relevante Tickets)",
        color:"#252b4a",
        icon:"sell"
      },
      data:{
        totalNumber:"11",
        data: [
          { title: 'Two', value: 4, color: '#e2cd71' },
          { title: 'Three', value: 6, color: '#9ed69a' },
        ]
      },
      filters:"2345",
    };
  }

  e(data);*/
};