import {Link} from "react-router-dom";

const FilterLink = (props) => {
    const linkRoute = () => {
        return `/tickets?ids=${props.filter}`
    }

    return (
        <Link style={{ textDecoration: 'none', color:"currentColor"}} to={linkRoute()}>
            {props.children}
        </Link>
    )
}

export default FilterLink;