import TicketIcon from "../../icons/TicketIcon";
import PieChartA from "../../mock/PieChartA";
import DonutPieChart from "./DonutPieChart";
import PxGridCard from "../../pxcore/grid/PxGridCard";
import MaterialIcon from "../../icons/MaterialIcon";
import FilterLink from "../filter/FilterLink";

const DonutPieChartCard = (props) => {

    const hasFilter = () => {
        if(props.filters !== undefined) {
            return true
        }
        return false;
    }

    return (
        <PxGridCard title={props.config.title} large >
          <div className="align-self-center no-shrink">
              <h2 className="m-b-0">
                  {hasFilter()? (
                      <FilterLink filter={props.filters}> {props.data.totalNumber} </FilterLink>
                  ): props.data.totalNumber}
                  {props.config.icon? <MaterialIcon icon={props.config.icon} color={props.config.color}/> : <></>}
              </h2>
              <h6 className="text-muted">
                {props.config.text}
              </h6>
          </div>
          <div className="ml-auto m-t-15">
              <DonutPieChart data={props.data.data} />
          </div>
        </PxGridCard>
    );
}
export default  DonutPieChartCard;