import moment from "moment";

export const calcDayDifference = (d1, d2) => {
    return d1.diff(d2, 'days');
}

export const calcHourDifference = (d1, d2) => {
    return d1.diff(d2, 'hours');
}

export const calcMinuteDifference = (d1, d2) => {
    return d1.diff(d2, 'minutes');
}

export const calcWeekDifference = (d1, d2) => {
    return d1.diff(d2, 'weeks');
}

export const calcDaysDifferenceCurrentDate = (d) => {
    let now = moment();
    return calcDayDifference(now, d);
}

export const calcHoursDifferenceCurrentDate = (d) => {
    let now = moment();
    return calcHourDifference(now, d);
}

export const calcMinutesDifferenceCurrentDate = (d) => {
    let now = moment();
    return calcMinuteDifference(now, d);
}

export const calcWeeksDifferenceCurrentDate = (d) => {
    let now = moment();
    return calcWeekDifference(now, d);
}

/**
 * TODO: translations..
 * @param date
 * @returns {string}
 */
export const relativDate = (date) => {
    const momentDate = moment(date);
    const differenceInDays = calcDaysDifferenceCurrentDate(momentDate);

    if(differenceInDays === 0) {
        const differenceInHours = calcHoursDifferenceCurrentDate(momentDate);
        if(differenceInHours === 0) {
			const differenceInMinutes = calcMinutesDifferenceCurrentDate(momentDate);
            return differenceInMinutes > 5 ? `vor ${differenceInMinutes} Minuten` : "Gerade eben";
        } else {
            return `vor ${differenceInHours} ` + (differenceInHours === 1 ? "Stunde" : "Stunden");
        }
    }
    else if (differenceInDays === 2) {
        return `Gestern`;
    }
    else if (differenceInDays < 7) {
        return `vor ${differenceInDays} ` + (differenceInDays === 1 ? "Tag" : "Tagen");
    }
    else if (differenceInDays >= 7 && differenceInDays < 14) {
        return `vor einer Woche`;
    } else {
        const differenceInWeeks = calcWeeksDifferenceCurrentDate(momentDate);
        return `vor ${differenceInWeeks} ` + (differenceInWeeks === 1 ? "Woche" : "Wochen");
    }
}