import moment from "moment/moment";
import PxTable from "../../../components/pxcore/table/PxTable";
import PxButton from "../../../components/pxcore/PxButton";
import { UserContext } from "../../../context/UserContextProvider";
import React, { useState, useContext } from 'react';
import { printInvoice } from "../../../factory/InvoiceFactory";
import { getPrettyName } from "../../../factory/CommonUtils";
import './InvoiceTableStyle.css';


const InvoiceTableView = (props) => {
    const { user, clientConfig } = useContext(UserContext);
    const { authorization } = useContext(UserContext);
    const [openFilter, setOpenFilter] = useState(false);
    const [currentYear, setCurrentYear] = useState(false);
    const [lastYear, setLastYear] = useState(false);
    const [lastBeforeYear, setLastBeforeYear] = useState(false);
    let start = null;
    let end = null;
    let totalDebtSum = 0;

    const createRows = () => {
        let rows = [];
        if (props?.invoices?.length > 0) {
            for (let i = 0; i < props.invoices.length; i++) {
                let invoice = props.invoices[i];

                let row = {
                    businessDate: moment(invoice.businessDate),
                    id: invoice.id,
                    number: invoice.number,
                    name: invoice.name,
                    netSum: invoice.netSum,
                    grossSum: invoice.grossSum,
                    debtSum: invoice.debtSum
                };

                totalDebtSum += invoice.debtSum;
                rows.push(row);
            }
        }

        return rows;
    }

    const setTotalDebt = (invoices) => {
        if (invoices?.length > 0) {
            for (let i = 0; i < props.invoices.length; i++) {
                let invoice = props.invoices[i];
                totalDebtSum += invoice.debtSum;
            }
        }
    };

    const getColumns = () => {
        if (props.columns !== undefined) {
            return props.columns;
        }
        return defaultColumns;
    }

    const numberWithCommas = (x) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x);
    }

    const renderDebtSum = (x, isTotalDebtSum) => {
        if (x === 0) {
            return <span style={isTotalDebtSum ? { color: '#a5c31f', 'font-size': '16px' } : { color: '#a5c31f' }}>{isTotalDebtSum ? <span><b>&emsp; &emsp;Offener Saldo:&nbsp;</b></span> : ''}bezahlt</span>

        } else {
            return <span style={isTotalDebtSum ? { color: '#e12222', 'font-size': '16px' } : { color: '#e12222' }}>{isTotalDebtSum ? <span ><b>&emsp; &emsp;Offener Saldo:&nbsp;</b></span> : ''}{numberWithCommas(x)}</span>

        }
    }

    const checkDate = (term, rowData) => {
        let show = false;
        let searchValue = getSearchInput()?.value;
        if(searchValue){
            show = rowData?.number?.includes(searchValue) || rowData?.name?.includes(searchValue);
        } else {
            show = true;
        }

        if (show && (start || end)) {
            let businessDate = rowData.businessDate.toDate().setHours(0, 0, 0, 0);
            let startDate = start ? start.setHours(0, 0, 0, 0) : null;
            let endDate = end ? end.setHours(0, 0, 0, 0) : null;
            show = (startDate ? (startDate <= businessDate) : true) && (endDate ? (businessDate <= endDate) : true);
        }
        return show;
    }

    const getSearchInput = () => {
        let inputs = document.getElementsByClassName("MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd");
        if(inputs?.length > 0){
            return inputs[0];
        }
        return null;
    }

    const updateStart = () => {
        let startInput = document.getElementById("startInput");
        if (startInput) {
            startInput.value = start ? start.toISOString().slice(0, 10) : null;
        }
    }

    const updateEnd = () => {
        let endInput = document.getElementById("endInput");
        if (endInput) {
            endInput.value = end ? end.toISOString().slice(0, 10) : null;
        }
    }

    const BusinessDateFilter = (columnDef, onFilterChanged) => {
        return (
            <div className="dateselector">
                <div className="from">
                    <span>von</span>
                    <input type="date" id="startInput"
                        onChange={(e) => {
                            start = e.target.value ? new Date(e.target.value) : null;
                            updateStart();
                            totalDebtSum = 0;
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                </div>
                <div className="to">
                    <span>bis</span>
                    <input type="date" id="endInput"
                        onChange={(e) => {
                            end = e.target.value ? new Date(e.target.value) : null;
                            updateEnd();
                            totalDebtSum = 0;
                            onFilterChanged(columnDef.tableData.id, e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    }

    const defaultColumns = [
        {
            title: "Datum",
            field: "businessDate",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            dateSetting: { locale: "de_AT" },
            render: (rowData) => <>{rowData.businessDate.format('DD.MM.YYYY')}</>,
            customSort: (a, b) => a.businessDate.valueOf() - b.businessDate.valueOf(),
            /*
            customFilterAndSearch: (term, rowData) => checkDate(term, rowData),
            filterComponent: ({ columnDef, onFilterChanged }) => (
                BusinessDateFilter(columnDef, onFilterChanged)
            ),*/
        },
        {
            title: "Nummer",
            field: "number",
            render: (rowData) => <>{rowData.number}</>
        },
        {
            title: "Titel",
            field: "name",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            render: (rowData) => <>{rowData.name}</>
        },
        {
            title: "Netto",
            field: "netSum",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            exportHeadStyle: {
                halign: 'right'
            },
            exportColumnStyle: {
                halign: 'right',
                font: 'WorkSans'
            },
            render: (rowData) => <>{numberWithCommas(rowData.netSum)}</>
        },
        {
            title: "Brutto",
            field: "grossSum",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            exportHeadStyle: {
                halign: 'right'
            },
            exportColumnStyle: {
                halign: 'right',
                font: 'WorkSans'
            },
            render: (rowData) => <>{numberWithCommas(rowData.grossSum)}</>
        },
        {
            title: "Offen",
            field: "debtSum",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            exportHeadStyle: {
                halign: 'right'
            },
            exportColumnStyle: {
                halign: 'right',
                font: 'WorkSans'
            },
            render: (rowData) => <>{renderDebtSum(rowData.debtSum, rowData.isTotalDebtSum)}</>
        },

        {
            title: "Kopie herunterladen",
            field: "exportCopy",
            sorting: false,
          	export: false,
            filterComponent: ({ columnDef, onFilterChanged }) => (
                <div>

                </div>
            ),
            headerStyle: {
                textAlign: 'right',
                paddingRight: '15px'
            },
            cellStyle: {
                textAlign: 'right',
                paddingRight: '15px'
            },
            render: (rowData) => printInvoiceButton(rowData.id, rowData.number)

        },
    ];

    const printInvoiceButton = (invoiceId, invoiceNumber) => {

        return (
            <PxButton onClick={() => { printInvoice(authorization.token, invoiceId, invoiceNumber + "_Kopie.pdf", clientConfig.endpointUrl, handleResponse) }}>
                herunterladen
            </PxButton>
        )
    };

    const handleResponse = (filename, response) => {
        if (response) {
            const contentType = 'application/pdf';
            var a = document.createElement('a');
            var blob = new Blob([new Uint8Array(response.data).buffer], { type: contentType });
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
        }
    }
    
    const ccustomFilter = () => {
        return (
            <>
                <PxButton onClick={() => { props.refetchFunction(currentYear ? undefined : moment().startOf('year').format('YYYY-MM-DD hh:mm:ss'), currentYear ? undefined : moment().endOf('year').format('YYYY-MM-DD hh:mm:ss'), openFilter ? true : undefined); setCurrentYear(!currentYear); setLastYear(undefined); setLastBeforeYear(undefined); }} btn={currentYear ? "btn-success" : ""} style={{ marginRight: "15px", marginTop: "5px", marginBottom: "5px" }} >
                    aktuelles Jahr
                </PxButton>

                <PxButton onClick={() => { props.refetchFunction(lastYear ? undefined : moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD hh:mm:ss'), lastYear ? undefined : moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD hh:mm:ss'), openFilter ? true : undefined); setLastYear(!lastYear); setCurrentYear(undefined); setLastBeforeYear(undefined); }} btn={lastYear ? "btn-success" : ""} style={{ marginRight: "15px", marginTop: "5px", marginBottom: "5px" }} >
                    {moment().subtract(1, 'years').format('YYYY')}
                </PxButton>

                <PxButton onClick={() => { props.refetchFunction(lastBeforeYear ? undefined : moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD hh:mm:ss'), lastBeforeYear ? undefined : moment().subtract(2, 'years').endOf('year').format('YYYY-MM-DD hh:mm:ss'), openFilter ? true : undefined); setLastBeforeYear(!lastBeforeYear); setCurrentYear(undefined); setLastYear(undefined); }} btn={lastBeforeYear ? "btn-success" : ""} style={{ marginRight: "30px", marginTop: "5px", marginBottom: "5px" }} >
                    {moment().subtract(2, 'years').format('YYYY')}
                </PxButton>

                <PxButton onClick={() => { props.refetchFunction(undefined, undefined, openFilter ? undefined : true); setOpenFilter(!openFilter); }} btn={openFilter ? "btn-success" : ""} style={{ marginRight: "30px", marginTop: "5px", marginBottom: "5px" }} >
                    Offene Rechnungen
                </PxButton>
            </>
        )
    }

    const getExportFileName = () => {
        var d = new Date();
        if (clientConfig?.invoiceConfig?.exportText) {
            const name = getPrettyName(user);
            let header = clientConfig.invoiceConfig.exportText + (name != null ? ' / ' + name : '') + '\n';
            return header + 'Datenexport vom: ' + d.toISOString().slice(0, 10) + ' ' + d.getUTCHours().toString().padStart(2, '0') + ':' + d.getUTCMinutes().toString().padStart(2, '0');
        }
        return 'Rechnung-Export_' + d.toISOString().slice(0, 10) + '-' + d.getUTCHours().toString().padStart(2, '0') + '-' + d.getUTCMinutes().toString().padStart(2, '0');
    }
    const getExportValue = (rowData, columnDef) => {

        switch (columnDef.field) {
            case 'number':
                return rowData.number;
            case 'name':
                return rowData.name;
            case 'businessDate':
                return rowData.businessDate.format('DD.MM.YYYY');
            case 'grossSum':
                return numberWithCommas(rowData.grossSum).trim();
            case 'netSum':
                return numberWithCommas(rowData.netSum).trim();
            case 'debtSum':
                if (rowData.debtSum === 0) {
                    return 'bezahlt';
                } else {
                    return numberWithCommas(rowData.debtSum).trim();
                }
            case 'deliveryType':
                return rowData.deliveryType;
            default:
                return '';
        }
    }


    return (
        <>
            <PxTable
                tableType='invoice'
                refetchFunction={props.refetchFunction}
                title={props.title}
                simpleTitle={props.simpleTitle}
                data={createRows()}
                columns={getColumns()}
                exportFileName={getExportFileName()}
                customFilter={ccustomFilter()}
                isLoaded={props.isLoaded}
                exportPdf
                exportCsv
                getExportValue={getExportValue}
                extraHeaders={renderDebtSum(totalDebtSum, true)}
            />
        </>
    )
};
export default InvoiceTableView;