import { PieChart } from 'react-minimal-pie-chart';

const defaultLabelStyle = {
    fontSize: '5px',
};

const shiftSize = 7;

const DonutPieChart = (props) => {
    return (
        <PieChart
            data={props.data} lineWidth={30}
            animate
        />
    );
};

export default  DonutPieChart;