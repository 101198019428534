const MaterialIcon = (props) => {

    const iconColor = () => {
        if(props.color) {
            return {
                color: props.color
            }
        } else {
            return {
                color: 'black'
            }
        }
    }

    const determinMaterialClass = () => {
        if(props.outlined) {
            return 'material-icons-outlined';
        }
        if(props.round) {
            return 'material-icons-round';
        }
        if(props.sharp) {
            return 'material-icons-sharp';
        }

        return 'material-icons'
    }

    return (
        <span className={determinMaterialClass() + ' noselect'} style={{...props.style, ...iconColor()}} >
            {props.icon}
        </span>
    );
};

export default MaterialIcon;