
export const apiConstants = {
    clientId: 1,
    baserUrl: "https://customer.techbold.at" , 		// "http://localhost:8080" "https://customer.techbold.at" "https://customer.banibis.at"
    basePrefix: "/", 								// "/PhoenixPortal/" "/"
    publicApiPrefix: "rest/public/v1",
	chatAppBaseUrl: "https://app.techbold.at/",		// "https://app.techbold.at/" "https://erp.banibis.at/"
	chatCookieDomain: "techbold.at"					// "techbold.at" "banibis.at"
}

export const httpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
}

/**
 * 
 * @param {*} endpoint 
 * @returns the concatinated valid request URL
 */
export const requestPublicUrl = (endpoint, pathParam) => {
    let url = apiConstants.baserUrl +
    apiConstants.basePrefix +
    apiConstants.publicApiPrefix + "/" + endpoint;

    if(pathParam !== undefined) {
        url = url + "/" + pathParam;
    }

    return url;
}

/**
 * 
 * @param {*} token 
 * @returns http request headers with bearer token set 
 */
export const createAuthHeaders = (token) => {
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + token);

    return headers;
};

/**
 * 
 * @param {*} method 
 * @param {*} headers 
 * @param {*} body 
 * @returns 
 */
export const createRequest= (method, headers, body = undefined) => {
    let requestOptions = {
        method: method,
        headers: headers,
        redirect: 'follow'
    };
    if(body !== undefined) {
        requestOptions.body = body;
    }
    return requestOptions;
}