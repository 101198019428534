export const getPrettyName = (user) => {
    let name = null;
    let address = user?.address;
    if(address) {
        if(address.isFirm) {
            name = address.firm;
        } else {
            name = address.firstName + ' ' + address.lastName
        }
    }
    return name;
}