import PxGridCard from "../pxcore/grid/PxGridCard";
import PxCard from "../pxcore/PxCard";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BulletPointCardList = (props) => {

    const renderCards = () => {
        if(props.data === undefined) {
            return (<></>);
        }
        return props.data.map((e, index) =>
            <div key={index} className="d-flex flex-row comment-row">
                <div className="p-2">{e.bullet}</div>
                <div className="comment-text w-100">
                    <h5>{e.headline}</h5>
                    <span className="name" style={{color: "#777"}}>{e.content}</span>
                    <p className="m-b-5">{e.footer}</p>
                </div>
            </div>
        );
    }

    return (
        <PxCard title={props.title}>
            <div className="comment-widgets ps ps--active-y">
                {renderCards()}
            </div>
        </PxCard>
    )
};

export default BulletPointCardList;