
import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import TicketHeader from "./TicketHeader";
import TicketDetailsCard from "../../../components/module/ticket/TicketDetailsCard";
import TicketPersonsCard from "./TicketPersonsCard";
import { useState, useEffect, useContext, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import TimelineView from "../../../components/timeline/TimelineView";
import ChatClientView from "./chat/ChatClientView";
import MailToLink from "../../../components/common/MailToLink";
import PxButton from "../../../components/pxcore/PxButton";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import TicketTableCard from "./TicketTableCard";
import { UserContext } from "../../../context/UserContextProvider";
import {fetchTickets} from "../../../factory/TicketFactory";
import PxCard from "../../../components/pxcore/PxCard";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TicketDetailsScreen = (props) => {
    const ref = useRef();
    const [ticket, setTicket] = useState({});
    const [childrenTickets, setChildrenTickets] = useState({});
    const [ticketIsLoaded, setTicketIsLoaded] = useState(false);
    const [childrenicketsIsLoaded, setChildrenicketsIsLoaded] = useState(false);
    const [idNotFound, setIdNotFound] = useState(false);
    let { ticketId} = useParams();
    const {clientConfig, authorization, user} = useContext(UserContext);

    /**
     * fetching ticket details from api
     */
    useEffect(() => {
            props.fetchTicket(ticketId, (ticket) => {
                if (ticket === undefined) {
                    setIdNotFound(true);
                }
                setTicket(ticket);
                setTicketIsLoaded(true);
            });
    }, [props, ticketId]);

    const solutionFeedback = () => {
        return (
            <PxButton style={{ color: "white", textDecoration: "none" }} onClick={newMail}>
            	Feedback geben
            </PxButton>
        )
    }
    
    const newMail = () => {
        ref.current.newMail("Feedback zu \"" + ticket.title + "\"");
    }
    
    useEffect(() => {
        handleRefetch();
    }, [ticket]);

    const handleResponse = (jsonResponse) => {
        setChildrenTickets(jsonResponse.tickets);
        setChildrenicketsIsLoaded(true);
    }
    const handleRefetch = () => {
        if(ticketIsLoaded && ticket !== undefined){
            fetchTickets(authorization.token, undefined, ticket.id, user.showTickets, handleResponse);
        }
    }
    /*
    render 404
    */
    if (idNotFound) {
        return (
            <>
                <PxGridRow>
                    <div className="col-md-6 align-self-center" style={{ paddingBottom: "20px" }}>
                        <h3 className="page-title">Ticket nicht gefunden!</h3>
                        <span className="breadcrumb-item">KUNDENPORTAL / </span>
                        <Link to="/tickets" style={{ color: '#3e5569' }} ><span className="breadcrumb-item"> TICKETS /</span></Link>
                        <span className="breadcrumb-item active"> 404</span>

                    </div>
                </PxGridRow>
            </>
        )
    }

    if (!ticketIsLoaded || !childrenicketsIsLoaded) {
        return (
            <>
                <PxGridRow>
                    <div className="col-md-6 align-self-center" style={{ paddingBottom: "20px" }}>
                        <h3 className="page-title">Ticket Details werden geladen</h3>
                        <span className="breadcrumb-item">KUNDENPORTAL / </span>
                        <Link to="/tickets" style={{ color: '#3e5569' }} ><span className="breadcrumb-item"> TICKETS /</span></Link>
                        <span className="breadcrumb-item active"> ...</span>

                    </div>
                </PxGridRow>
            </>
        )
    }

    return (
        <>
            <PxGridRow>
                <div className="col-md-6 align-self-center" style={{ paddingBottom: "20px" }}>
                    <h3 className="page-title">Ticket Details</h3>
                    <span className="breadcrumb-item">KUNDENPORTAL / </span>
                    <Link to="/tickets" style={{ color: '#3e5569' }} ><span className="breadcrumb-item"> TICKETS /</span></Link>
                    <span className="breadcrumb-item active"> {ticket.number}</span>

                </div>
            </PxGridRow>

            <PxGridRow>
                <TicketHeader avatar={props.fetchAvatar} ticket={ticket} />
            </PxGridRow>

            <PxGridRow>
        		<>
					<PxGridColumn large>
	                    <TicketDetailsCard ticket={ticket} onSolution={solutionFeedback()} />
		                {ticket?.chat !== undefined && ticket?.chat ? (
		                	<ChatClientView ticket={ticket} />
		                ) : (
	                        <></>
	                    )}
	                    <TicketPersonsCard ticket={ticket} />
	                </PxGridColumn>
	                <TimelineView ref={ref} ticket={ticket} />
				</>
            </PxGridRow>
            <PxGridRow>
            </PxGridRow>
            <PxGridRow>
                {childrenTickets.length !==0 ? <TicketTableCard ticket={ticket} childrenTicket = {childrenTickets}/> : (<></>)}
            </PxGridRow>
        </>
    )
};
export default TicketDetailsScreen;