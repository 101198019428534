import {createAuthHeaders, createRequest, httpMethods, requestPublicUrl} from "./ApiUtils";

const ticketEndpoint = "media"
/**
 *
 * @param token
 * @param id
 * @param f callback function
 */
export const fetchMedia = (token, id, f) => {
	
	if(id != null) {
	    let headers = createAuthHeaders(token);
	    let requestOptions = createRequest(httpMethods.GET, headers);
	
	    fetch(requestPublicUrl(ticketEndpoint, id), requestOptions)
	        .then(response => response.text())
	        .then(result => f(JSON.parse(result)))
	        .catch(err => {
	            console.log(err);
	
	            //TODO..
	            f(null);
	        });
	}
	
	return null;
};