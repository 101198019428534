import MaterialIcon from "./MaterialIcon";
import {UserContext} from "../../context/UserContextProvider";
import {apiConstants} from "../../factory/ApiUtils";
import React, {useContext, useEffect, useState} from "react";

const NotificationBellIcon = (props) => {
    const [chatNotifierLoaded, setChatNotifierLoaded] = useState(false);
    const {authorization, user} = useContext(UserContext);
	
    useEffect(() => {
        if(!chatNotifierLoaded) {
            window.initChatNotifier(user, authorization?.token, apiConstants?.chatAppBaseUrl, '#ticket-notifier .count');
            setChatNotifierLoaded(true);
        }
    });

    return (
		<div class="" id="ticket-notifier">
			<div class="count"></div>
            <MaterialIcon icon="notifications"            
	            color={props.color} 
	            outlined={props.outlined}
	            round={props.round}
	            sharp={props.sharp} 
	            style={props.style}
	        />
        </div>
    );
};

export default NotificationBellIcon;