import React, {useContext, useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import Alert from '@mui/material/Alert';
import {apiConstants} from "../../factory/ApiUtils";
import {UserContext} from "../../context/UserContextProvider";
import PxGridColumn from "../pxcore/grid/PxGridColumn";
import PxButton from "../pxcore/PxButton";
import {BeatLoader} from "react-spinners";
import {fetchTimeline} from "../../factory/TimelineFactory";
import {sendEmail} from "../../factory/TicketFactory";
import './timeline.style.css';
import Timeline from './timeline.js';
import TimelineMail from './timeline.mail.js';
import moment from "moment/moment";
import 'moment/locale/de';

import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce/tinymce';

const TimelineView = forwardRef((props, ref) => {
	  useImperativeHandle(ref, () => ({
	    newMail(subject) {
	      handleMailButtonClick(true, subject);
	    }
	  }));
	  
	const SENDER_EMAIL = "ticket@techbold.at";

    const [timeline, setTimeline] = useState({});
    const [timelineLoaded, setTimelineLoaded] = useState(false);
    const [showNewMail, setShowNewMail] = useState(false);
    const [newMailSubject, setNewMailSubject] = useState('');
	const [newMailTo, setNewMailTo] = useState('');
	const [newMailCC, setNewMailCC] = useState('');
	const [newMailBCC, setNewMailBCC] = useState('');
	const [newMailFrom, setNewMailFrom] = useState(SENDER_EMAIL);
    const [newMailBody, setNewMailBody] = useState('');
    const {authorization, user} = useContext(UserContext);
	const [documentFiles, setDocumentFiles] = useState([]);
	const [sendMailErrors, setSendMailErrors] = useState('');
	const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
   	const editorRef = useRef(null);
	const inputRef = React.useRef(null);


    const handleResponse = (jsonResponse) => {
        setTimeline(jsonResponse.entities);
        setTimelineLoaded(true);
    }
    
    useEffect(() => {
        if(!timelineLoaded && props.ticket?.id !== undefined) {
            fetchTimeline(authorization.token, props.ticket.id, handleResponse);
        }
    }, []);
    
    useEffect(() => {
        if(showNewMail) {
            
        }
    }, [documentFiles]);
    
    function onLoadIFrame(id) {
        var entry = document.getElementById('entry-' + id);
        var iframe = document.getElementById('iframe-' + id);

		try {
			var doc = iframe?.contentDocument || iframe?.contentWindow?.document;
			var body = doc?.body;
			iframe.style.height = (body ? body.scrollHeight : '300') + 'px';
		} catch(e) { console.log(e); };

	   // iframe.style.height = '300px';
        Timeline.fadeinRecalc(entry);
	}
    const getDateFormatted = (date) => {
        return moment(date).locale('de').format('dd. DD.MM.yyyy HH:mm')
    }
    const getEmailFormatted = (email) => {
        return <span className='val' title='{email.address}'>{email.personal ? email.personal : email.address}</span>;
    }
    const getEmailsFormatted = (prefix, emails) => {
        if(emails) {
            return (<> 
            <span className='prefix'>{prefix}</span> 
            	{emails.map((e, index) =>
            	getEmailFormatted(e))}
            )</>);
        }
        return "";
    }
    const toggleAddresses = () => {
    	TimelineMail.toggleAddresses(null, document.getElementById("toggleAddresses"));
    }
    const handleMailButtonClick = (showNewMail, subject, e) => {
		if(!showNewMail){
			setShowNewMail(showNewMail);
			resetEditor()
		} else{
			setShowNewMail(showNewMail);
			setNewMailSubject(subject);
			setNewMailBody(generateMailBody(e));
		 	setNewMailTo(e?.from.address ? e.from.address : '');
		}
    }
    const renderNewMailButton = (text, mailSubject, e) => {
        return (
            <PxButton onClick={handleMailButtonClick.bind(this, true, mailSubject, e)} btn="btn-neutral">{text}</PxButton>
        )
    }
    const renderCloseMailButton = () => {
        return (
            <PxButton onClick={handleMailButtonClick.bind(this, false)} btn="btn-neutral">
                E-Mail verwerfen
            </PxButton>
        )
    }
    const handleSendMailResponse = (jsonResponse) => {
		if(jsonResponse?.sent){
			if(props.ticket?.id) {
				fetchTimeline(authorization.token, props.ticket.id, handleResponse);
			}
			setShowNewMail(false);
			resetEditor();
		} else {
			setSendMailErrors('Die Nachricht konnte nicht gesendet werden')
		}
    }
    const handleSendMailClick = async () => {	
		let files = [];
		for(let file of documentFiles){
			if(file){
				const buffer = await file.arrayBuffer();
				let byteArray = new Int8Array(buffer);
				var base64 = btoa(
					new Uint8Array(byteArray)
					  .reduce((data, byte) => data + String.fromCharCode(byte), '')
				  );
				let data= {
					name: file?.name,
					contentBytes: base64,
					contentType: file?.type,
					isInline: true
				}
				files.push(data)
			}
		}
		let payload = {
			from: newMailFrom,
			to: parseInputToArray(newMailTo),
			cc: parseInputToArray(newMailCC),
			bcc: parseInputToArray(newMailBCC),
			subject: newMailSubject,
			communicationNumber: ' [techbold.at #'+props.ticket.number + ']',
			bodyHtml: newMailBody?.replace(/"/g, "'"),
			bodyPlain: newMailBody?.replace(/<[^>]*>?/gm, ''),
			data: files
        };
		sendEmail(authorization.token, apiConstants.clientId, props.ticket.id, payload, handleSendMailResponse);
    }

	function resetEditor(){
		setNewMailSubject('');
		setNewMailTo('');
		setNewMailFrom(SENDER_EMAIL);
		setNewMailBody('');
		setDocumentFiles([]);
		setNewMailCC([]);
		setNewMailBCC([]);
		setSendMailErrors('');
		setAlert(false);
		setAlertContent('');
	}
    const renderSendMailButton = () => {
        return (
            <PxButton onClick={handleSendMailClick.bind(this, false)} btn="btn-green" style={{marginLeft:"15px"}}>
                E-Mail versenden
            </PxButton>
        )
    }
	const generateMailBody = (e) => {	
		
		if(e) {
			var to = e?.to[0]?.personal ? e.to[0].personal + (e?.to[0]?.address ? ' [' + e?.to[0]?.address + ']' : '') : e?.to[0]?.address;
			var from = e?.from?.personal ? e.from.personal + (e?.from?.address ? ' [' + e?.from?.address + ']' : '')  : e?.from.address;
	
			let body = e ? "<br><div><div style='border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0cm 0cm 0cm'><p class='MsoNormal'> <b><span lang='DE'>Von:</span></b> <span lang=DE>" + from + "<br><b>Gesendet:</b> " + e.sentDate + "<br> <b>An:</b> "+ to +" <br><b>Betreff:</b> " + e.subject  +  ' [techbold.at #'+props.ticket.number + ']' + "<o:p></o:p> </span></p></div></div> " : '';
			
			var previousMailBody = "";
			try {
				var iframe = document.getElementById('iframe-' + e.id);
				var doc = iframe?.contentDocument || iframe?.contentWindow?.document;
				previousMailBody = doc?.body.innerHTML;
			} catch(e) { console.log(e); };

			return body + "<br>" + previousMailBody;
		}
		return '';
	}
	const handleFileInputChange = function(e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			
			var length = 0;
			if(documentFiles && documentFiles?.length > 0) {
				for(var i = 0; i < documentFiles?.length; i++) {
					length += documentFiles[i].size;
				} 
			}
			length += e.target.files[0].size;
			if(length > 1024 * 1024 * 20) {
				setAlert(true);
				setAlertContent('maximal 20 MB erlaubt');
			} else {
				setAlert(false);
				setAlertContent('');
				setDocumentFiles(documentFiles => [...documentFiles, e.target.files[0]]);
			}
		}
	  };

	  const parseInputToArray = function(input) {
		let value = [];
		if(input && input?.length > 0){
			const arr = input.split(";");
			if(arr && arr?.length > 0){
				arr.map((mail, index) => {
					value.push(mail?.trim())				
				}) 
			}
		}
		return value;
	  };

	function deleteFile(name) {
		
		var files = [].concat(documentFiles);
		if(files && files?.length > 0){
		for(var i = 0; i < files?.length; i++) {
			if(files[i] && files[i]?.name === name) {
				files.splice(i, 1);
				break;
			}
		}
		setDocumentFiles(files);
		}
        console.log('file deleted')
		return false;
    }
	
	const getFormattedFileSize = (bytes) => {
		if(bytes >= 1073741824) { 
			bytes = (bytes / 1073741824).toFixed(2) + " gb"; 
		}
		else if(bytes >= 1048576){ 
			bytes = (bytes / 1048576).toFixed(2) + " mb"; 
		}
		else if(bytes >= 1024){ 
			bytes = (bytes / 1024).toFixed(2) + " kb"; 
		}
		else if(bytes > 1){ 
			bytes = bytes + " bytes"; 
		}
		else if(bytes == 1){ 
			bytes = bytes + " byte"; 
		}
		else{ 
			bytes = "0 bytes"; 
		}
		return bytes;
	}
	const getFormattedFileType = (filename) => {
		var index = filename.lastIndexOf('.');
		
		return index ? filename.substring(index+1) : '';
	}

    const renderNewMail = () => {
        return <div className="ent">
        	<div className="sl-ent">
        		<div className="sl-line solid">
        			<div className="img"><svg><use xlinkHref="#svg-envelope"></use></svg></div>
        		</div>
        		<div className="content">
	                <div className="bubble" style={{backgroundColor:"white", borderColor:"#e6e6e6"}}>
	                	<svg className="arrow" style={{fill:"white", stroke:"#e6e6e6"}}><use xlinkHref="#svg-arrow"></use></svg>
		                <div className="content-wrap mail">
		                    <div>
		                    	<div className="header"></div>
		                    	<div className="sendcommand"></div>
		                    	<div className="content tinymce-bright edit">
		                    		<div className="clear"></div>
		                    		<div className="fields">
		                    			{alert ? <Alert style={{border: "1px solid #ef5350"}} severity='error' onClose={() => { setAlert(false); setAlertContent(''); }}>{alertContent}</Alert> : <></> }
		                    			<div className="field from"><span>Absender</span><div><input value={newMailFrom} readOnly/></div></div>
		                    			<div className="field to"><span>An<span className="angle up"><i className="fa fa-angle-down" id="toggleAddresses" onClick={toggleAddresses}></i></span></span><div><input onChange={(e)=> setNewMailTo(e.target.value)}  value={newMailTo}/></div></div>
		                    			<div className="field cc hide"><span>Cc</span><div><input value={newMailCC} onChange={(e)=> setNewMailCC(e.target.value)}/></div></div>
		                    			<div className="field bcc hide"><span>Bcc</span><div><input value={newMailBCC} onChange={(e)=> setNewMailBCC(e.target.value)}/></div></div>
										<div className="field attachment">
											<span>Anhang</span>
				                    		<div className="files-wrap">
												<div className="attachments">
											{ documentFiles && documentFiles.length > 0 ?
											 documentFiles.map((fileData, index) => {
												return (
													<span>
														<a className="file" target="_blank"><span className="ext">{getFormattedFileType(fileData.name)}</span><span className="name">{fileData.name}</span><span class="size">{getFormattedFileSize(fileData.size)}</span></a>
														<a onClick={(e)=> deleteFile(fileData.name)} className="del"><i className="fa fa-times"></i></a>
													</span>
												);
											}) : <></>}
												</div>
												<div className="input-wrap">
													<label id="label-file-upload" htmlFor="input-file-upload">
														<svg><use xlinkHref="#svg-upload"></use></svg>
														<span>Dateien wählen</span>
														<input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleFileInputChange} />
													</label>
												</div>
				                    		</div>
				                    	</div>
		                    			<div className="field subject"><span>Betreff</span><div><input onChange={(e)=> setNewMailSubject(e.target.value)} value={newMailSubject}/></div><span class="id">[techbold.at #{props.ticket.number}]</span></div>
		                    		</div>
		                    		<Editor
										id={"textarea-editor-" + props.ticket.id}
										 onInit={(evt, editor) => editorRef.current = editor}
										 value={newMailBody}
										 onEditorChange={(content)=> setNewMailBody(content)}
										 init={{
										   menubar: false,
										   height: 500,
										   initValue: {newMailBody},
										   invalid_elements: 'div',
										   valid_elements: '+*[*]',
										   plugins: [
											 'autolink', 'autoresize', 'image', 'link'
										   ],
										   insert_toolbar: 'quickimage quicktable',
										   selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
										   toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link | forecolor backcolor',
										   paste_data_images: true,
										   min_height: 500,
										   max_height: 1000,
										   toolbar_mode :'wrap',
										   block_unsupported_drop: false,
										   content_style: '.mce-content-body {font-size:13px;font-family:Arial,sans-serif;}',
										   branding: false
										 }}
									/>
		                    	</div>
		                    </div>
		                </div>
	                </div>
                </div>
            </div>
        </div>
    }
    
    const renderComponents = () => {
        if(timeline === undefined || timeline.length == 0) {
            return (<div className="centered-el"><h1>keine E-Mails vorhanden</h1></div>);
        }
        return timeline.map((e, index) =>
            <div key={index} className="ent">
            	<div className="sl-ent">
            		<div className={"sl-line" + (index+1 != timeline.length ? ' solid' : '')}>
            			<div className="img"><svg><use xlinkHref="#svg-envelope"></use></svg></div>
            		</div>
            		<div className={"content" + (e.pending ? " message info" : "")}>
		                <div className="bubble">
		                	<svg className="arrow" style={{stroke:"#e6e6e6"}}><use xlinkHref="#svg-arrow"></use></svg>
			                <div id={e.pending ? "" : "entry-" + e.id} className={"content-wrap mail" + (e.pending ? "" : " fadeout")}>
			                    <div>
			                    {e.pending ? (
				         			<>
				         			<div><h3>Die Nachricht wurde am {getDateFormatted(e.sentDate)} versendet</h3></div>
				                    </>
				     			) : (
                                    <>
			                    	<div className="header"><span className="date"><span className="datetime">{getDateFormatted(e.sentDate)}</span></span><span className="name">{e.username ? "»" + e.username + "«" : ""}</span></div>
			                    	<div className="sendcommand">{!showNewMail ? <>{renderNewMailButton('antworten', 'AW: ' + e.subject, e)}</> : <></>}</div>
			                    	<div className="content tinymce-bright readonly">
			                    		<h3>{e.subject}</h3>
				                    	<div className="info">
				                    		{getEmailFormatted(e.from)} {getEmailsFormatted("An:", e.to)} {getEmailsFormatted("Cc:", e.cc)} {getEmailsFormatted("Bcc:", e.bcc)}
				                    	</div>
				                    	<div className="attachment">
				                    		<div className="attachments">
											{ e.attachments && e.attachments.length > 0 ?
											 e.attachments.map((fileData, index) => {
												return (
													<span><a className="file" target="_blank" href= {apiConstants.baserUrl + apiConstants.basePrefix + apiConstants.publicApiPrefix +"/media/file/download?communicationEmailId=" + e.id + '&communicationId=' + e.communicationId + '&filename=' + fileData.name + '&token=' + authorization.token} ><span class="ext">{fileData.extension}</span><span class="name">{fileData.name}</span><span class="size">{fileData.size + "  kb"}</span></a></span>
												);
											}) : <></>}
				                    		</div>
				                    	</div>
				                    	<div>
										<iframe className="correct" id={"iframe-" + e.id} onLoad={onLoadIFrame.bind(this, e.id)} sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox" src={apiConstants.baserUrl + apiConstants.basePrefix + apiConstants.publicApiPrefix + "/timeline/entity?ticketId=" + props.ticket.id + "&id=" + e.id + "&token=" + authorization.token}></iframe>										</div>
			                    	</div>
			                    	</>
								)}
			                    </div>
			                </div>
		                </div>
	                </div>
                </div>
            </div>
        );
    }
    
    return (
        <PxGridColumn xlarge>
            <div className="card timeline-container" id="timeline" style={{minHeight:"520px"}}>
                <div className="card-body">
                	<h5 className="card-title">
                		<div className="menu">Kommunikation
                			<div className="mail">{timelineLoaded ? (!showNewMail ? <>{renderNewMailButton('neue E-Mail', props.ticket.title)}</> : <>{renderCloseMailButton()}{renderSendMailButton()}</>) : <></>}</div>
                		</div>
                	</h5>
   				{timelineLoaded ? (
         			<>
         			<div className="chrono-timeline">
         			{showNewMail ? 
                		<>{renderNewMail()}</>
                	 : <></>}
                      	{renderComponents()}
                    </div>
                    </>
     			) : (
                    <div className="centered-el">
                        <BeatLoader color="#86bacf" loading={true} size={20} speedMultiplier={0.6}/>
                    </div>
				)}
                </div>
            </div>
            <div style={{display:"none", zIndex: 10000, position:"relative", width:"70px", background:"white", padding:"100px"}} id="svg-icons">
	            <svg id="svg-envelope" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path fill="inherit" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
				</svg>
				<svg id="svg-arrow" className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271.5 132.42" style={{fill:"inherit", stroke:"inherit"}}>
					<path style={{fill:"inherit", stroke:"inherit", strokeWidth:"15px"}} d="M310.16,290.73l-126.67,128h266l-126.67-128A8.92,8.92,0,0,0,310.16,290.73Z" transform="translate(-181 -287.58)"></path>	
					<rect style={{fill:"inherit", stroke:"none"}} y="120" width="300" height="24"></rect>
				</svg>
				<svg id="svg-upload" viewBox="0 -256 1792 1792" version="1.1" width="100%" height="100%" style={{width:"15px", display:"inline-block", float:"left", marginLeft:"7px"}}>
				  <g transform="matrix(1,0,0,-1,68.338983,1247.4576)" id="g3351">
				    <path d="M 1664,480 V -96 q 0,-13 -9.5,-22.5 -9.5,-9.5 -22.5,-9.5 H 32 q -13,0 -22.5,9.5 Q 0,-109 0,-96 V 480 Q 0,493 9.5,502.5 19,512 32,512 h 192 q 13,0 22.5,-9.5 Q 256,493 256,480 V 128 h 1152 v 352 q 0,13 9.5,22.5 9.5,9.5 22.5,9.5 h 192 q 13,0 22.5,-9.5 9.5,-9.5 9.5,-22.5 z m -320,352 q 0,-26 -19,-45 -19,-19 -45,-19 H 1024 V 320 q 0,-26 -19,-45 -19,-19 -45,-19 H 704 q -26,0 -45,19 -19,19 -19,45 V 768 H 384 q -26,0 -45,19 -19,19 -19,45 0,26 19,45 l 448,448 q 19,19 45,19 26,0 45,-19 l 448,-448 q 19,-19 19,-45 z" id="path3353" style={{fill:"currentColor"}}></path>
				  </g>
				</svg>
			</div>
        </PxGridColumn>
    );
});
export default TimelineView;