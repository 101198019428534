import './PxStyle.css';

const PxHeader = (props) => {
    return (
        <header className="topbar" >
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
                {/*
                logo <-> brand
                */}

                <div className="navbar-header">
                    <a className="nav-toggler waves-effect waves-light d-block d-md-none px-nav-toggler" >
                        <i className="ti-menu ti-close"></i>
                    </a>

                    <div className="navbar-brand" >
                        {props.brand}
                    </div>

                    {/*
                        the menue x on mobile? TODO: 
                    */}
                     <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
                            data-toggle="collapse" data-target="#navbarSupportedContent" 
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i
                                class="ti-more"></i>
                    </a>
                </div>

                {/*
                    header content
                */}
                <div id="navbarSupportedContent" className="navbar-collapse collapse">
                    <ul class="navbar-nav float-left mr-auto ml-3 pl-1">
                        {props.itemsLeft}
                    </ul>

                    
                    <ul class="navbar-nav float-right topbar-right">
                        {props.itemsRight}
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default PxHeader;