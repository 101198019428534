import PxGridCard from "../../pxcore/grid/PxGridCard";
import FilterLink from "../filter/FilterLink";
import MaterialIcon from "../../icons/MaterialIcon";
import { BeatLoader } from "react-spinners";

const EmptyLoadingCard = (props) => {

    return (
        <>
            <PxGridCard small cardBodyClass="total_number" styleCard={{ minHeight: '141.17px'}}>
                <div style={{margin: 'auto', marginTop: '40px'}}>
                    <BeatLoader color="#86bacf" loading={true} size={10} speedMultiplier={0.8} />
                </div>
            </PxGridCard>
        </>
    )
};

export default EmptyLoadingCard;