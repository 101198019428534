import MainView from "./MainView";
import LoginScreen from "./LoginScreen";
import { UserContext } from "../context/UserContextProvider";
import {useContext} from 'react';

const PxPortal = (props) => {
    const {user, clientConfig} = useContext(UserContext);
    
    if(clientConfig) {
        if(!user) {
            return (
                <LoginScreen/>
            )
        }

        return (
            <MainView />
        )
    } else {
		return (
		    <></>
		)
    }
}
export default PxPortal;