import React, {useContext, useEffect, useState} from "react";
import {TicketSearchContext} from "../../../context/TicketSearchContext";
import {Link, useHistory} from "react-router-dom";
import {toPrettyName} from "../../../components/common/PersonAddressUtil";
import moment from "moment/moment";
import TicketTableView from "../ticket/TicketTableView";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import {relativDate} from "../../../components/common/DateUtils";
import StatusAvatar from "../../../components/common/StatusAvatar";
import MaterialIcon from "../../../components/icons/MaterialIcon";
import {fetchTickets} from "../../../factory/TicketFactory";
import {UserContext} from "../../../context/UserContextProvider";

const DashboardTableCard = (props) => {
    const [ticketRows, setTicketRows] = useState([]);
    const {fetchTicketAvatar} = useContext(TicketSearchContext);
    const [tickets, setTickets] = useState([]);
    const [epicLoaded, setEpicLoaded] = useState(false);
    const wConfig = JSON.parse(props.config.config);
    const history = useHistory();
    const {authorization, user} = useContext(UserContext);

    //if columns in config, when not render below defaultConfig
    const columns = [
        {
            title: "Ticket",
            field: "ticket",
            export: false,
            headerStyle: {width: "130px"},
            render: (rowData) => <StatusAvatar imageId={rowData.avatarId} showSpinner={rowData.avatarId !== undefined} avatar={rowData.fetchAvatar} color={rowData.statusExternalColor || 'grey'}/>
        },
        {
            title: "Nummer",
            field: "number",
            headerStyle: {width: "130px"},
        },
        {
            title: "Name",
            field: "name",
            headerStyle: {width: "300px", paddingRight:'300px'}
        }
    ];

    const handleResponse = (jsonResponse) => {
        setTickets(jsonResponse.tickets);
    }
    
    useEffect(() => {
        handleRefetch();

    }, [])

    const handleRefetch = () => {
        if(wConfig.ticket_mapping != null) {
            fetchTickets(authorization.token, wConfig.ticket_mapping, undefined, user.showTickets, handleResponse);
            setEpicLoaded(true); 
        }
    }

    function handleIconClick(e, mapping, id, count){
        e.stopPropagation();
        if(count !== 0){
            if(mapping == undefined){
                history.push(`children/all/${id}`);   
            } else {
                history.push(`children/${mapping}/${id}`);   
            }   
        }
    }

    const getColumns = () => {
        if (wConfig.columns !== undefined) {
            wConfig.columns.map((data, index) => (
                            columns.push({
                                title: data.title,
                                field: "tickets",
                                export: false,
                                headerStyle: {width: "50px"},
                                render: (rowData) =>
                                    <div className="align-self-center no-shrink px-dash-icon">
                                        <span onClick={(e) => handleIconClick(e, data.status_mapping, rowData.id, (rowData && rowData.children !== undefined && data.status_mapping !== undefined)? rowData.children.filter(p => p.statusExternal.mapping === data.status_mapping).length : rowData.children !== undefined ? rowData.children.length : 0)}>
                                        <span className="round" style={{backgroundColor: data.color, width:'45px', height:'45px', lineHeight:'45px'}}>
                                            <span style={{display:'flex', justifyContent:'center', fontSize: '20px'}}>{(rowData && rowData.children !== undefined && data.status_mapping !== undefined)? rowData.children.filter(p => p.statusExternal.mapping === data.status_mapping).length : rowData.children !== undefined ? rowData.children.length : 0}</span>
                                        </span>
                                    </span>
                                    </div>
                        })))
                    }
        return columns;
    }
    useEffect(() => {
        if(tickets?.length > 0 && epicLoaded) {
            let rows = [];
            for (let i = 0; i < tickets.length; i++) {
                let ticket = tickets[i];

                let row = {
                    id: ticket.id,
                    avatarId : ticket.avatarId,
                    fetchAvatar: fetchTicketAvatar,    
                    destinationUrl: 'tickets',
                    number: ticket.number,
                    statusExternalColor: ticket.statusExternal?.color,
                    numberRow: (<Link to={`tickets/epic/${ticket.id}`}><span
                        className="round ticket-link">{ticket.number}</span></Link>),
                    name: ticket.title,
                    status: ticket.statusExternal != null ? ticket.statusExternal.name : "",
                    statusRow: (<span className="badge" style={{
                        backgroundColor: ticket.statusExternal?.color || 'grey',
                        color: "white",
                        fontSize: "100%",
						minWidth:"100px"
                    }}>{ticket.statusExternal?.name}</span>),
                    editor: ticket.persons?.find(p => p.roles && p.roles.indexOf("Bearbeiter") !== -1) !== undefined ? toPrettyName(ticket.persons?.find(p => p.roles && p.roles.indexOf("Editor") !== -1).address) : "",
                    createDateRow: relativDate(ticket.createDate),
                    createDate: moment(ticket.createDate),
                    children: ticket.children?.tickets
                };

                rows.push(row);
            }

            setTicketRows(rows);
        }
    }, [tickets]);

    return (
        <PxGridColumn xlarge>
            <TicketTableView title= {props.config.title} simpleTitle columns={getColumns()} refetchFunction={handleRefetch} tickets ={ticketRows}/>
        </PxGridColumn>
    )
}
export default DashboardTableCard;