import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import PxGridColumn from "../../../components/pxcore/grid/PxGridColumn";
import TicketTableView from "./TicketTableView";
import TicketDetailsScreen from "./TicketDetailsScreen";
import {
    Switch,
    Route,
    useRouteMatch, useParams, useLocation
} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {TicketSearchContext} from "../../../context/TicketSearchContext";
import {toPrettyName} from "../../../components/common/PersonAddressUtil";
import moment from "moment/moment";

/**
 * 
 * @param {*} props 
 * @returns ticket screen view
 */
const TicketScreen = (props) => {
    const [ticketRows, setTicketRows] = useState([]);
    const {tickets, fetchTicket,  fetchTicketAvatar, fetchTicketList, loaded} = useContext(TicketSearchContext);
    let {path, url} = useRouteMatch();
    let filterIds = useQuery().get('ids');
    let { mapping, ticketId} = useParams();

    useEffect(() => {
        if(filterIds != null) {
            fetchTicketList(filterIds, undefined);
        } else if (tickets.length == 0){
            fetchTicketList(undefined, undefined);
        }
    }, [])

    useEffect(() => {
        let rows = [];
            for(let i = 0; i < tickets.length; i++) {
                let ticket = tickets[i];

                let row = {
                    id: ticket.id,
                    avatarId : ticket.avatarId,
                    fetchAvatar: fetchTicketAvatar,
                    destinationUrl: `${url}`,
                    number: ticket.number,
                    name: ticket.title,
                    type: ticket.type != null ? ticket.type.name : "",
                    status: ticket.statusExternal != null ? ticket.statusExternal.name : "",
                    statusExternalColor: ticket.statusExternal?.color,
                    editor: ticket.persons?.find(p => p.roles && p.roles.indexOf("Editor") !== -1) !== undefined ? toPrettyName(ticket.persons?.find(p => p.roles && p.roles.indexOf("Editor") !== -1).address) : "",

                    requestor: ticket.persons?.find(p => p.roles && p.roles.indexOf("Requestor") !== -1) !== undefined ? toPrettyName(ticket.persons?.find(p => p.roles && p.roles.indexOf("Requestor") !== -1).address) : "",
                    createDate: moment(ticket.createDate)
                };

                rows.push(row);
            }
        
        setTicketRows(rows);
    }, [tickets]);

    return (
        <>
            <Switch>
                <Route exact path={path}>
					
                    <PxGridRow>
                        <div className="col-md-6 align-self-center" style={{paddingBottom:"20px"}}>
	                        <h3 className="page-title">Tickets</h3>
	                        <span lcass="breadcrumb-item">KUNDENPORTAL / </span>
	                        <span className="breadcrumb-item active"> TICKETS</span>
                        </div>
                    </PxGridRow>
                    <PxGridRow>
						<PxGridColumn fullscreen>
                        	<TicketTableView isLoaded={loaded} refetchFunction={fetchTicketList} tickets ={ticketRows}/>
						</PxGridColumn>
                    </PxGridRow>
					
                </Route>
                <Route path={`${path}/:ticketId`}>
                	<TicketDetailsScreen fetchAvatar={fetchTicketAvatar} fetchTicket={fetchTicket}/>
                </Route>
            </Switch>
        </>
    );
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default TicketScreen;