import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import UserAddressView from "./UserAddressView";
import UserAvatarCardView from "./UserAvatarCardView";
import { UserContext } from "../../../context/UserContextProvider";
import { useContext, useEffect } from 'react';
import './UserViewStyle.css';

/**
 * user settings screen
 * @param {*} props 
 */
const UserScreen = (props) => {
    const { user, getAvatar, fetchUser } = useContext(UserContext);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]); // Added fetchUser to the dependency array

    return (
        <>
            <PxGridRow>
                <div className="col-md-6 align-self-center" style={{paddingBottom:"20px"}}>
                    <h3 className="page-title">Benutzerinformationen</h3>
                    <span className="breadcrumb-item">KUNDENPORTAL / </span>
                    <span className="breadcrumb-item active"> BENUTZER</span>
                </div>
            </PxGridRow>

            <PxGridRow>
                <UserAvatarCardView user={user} getAvatar={getAvatar}/>
                <UserAddressView user={user} />
            </PxGridRow>
        </>
    );
};
export default UserScreen;