import TotalNumberDiagram from "../diagramm/TotalNumberDiagram";
import DonutPieChartCard from "../diagramm/DonutPieChartCard";

const components = {
    totalNumberDiagram: TotalNumberDiagram,
    donutPieChart: DonutPieChartCard
}

export const getComponentByName = (name) => {
    let component = components[name];
    return component;
}