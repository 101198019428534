import React, { useContext, useEffect, useState } from "react";
import { apiConstants } from "../../../../factory/ApiUtils";
import { UserContext } from "../../../../context/UserContextProvider";
import PxGridColumn from "../../../../components/pxcore/grid/PxGridColumn";
import { BeatLoader } from "react-spinners";

const ChatClientView = ({ ticket }) => {
    const [chatLoaded, setChatLoaded] = useState(false);
    const { authorization, user } = useContext(UserContext);

    useEffect(() => {
        if (!chatLoaded && ticket?.id !== undefined) {
            window.initChat(user, authorization.token, apiConstants.chatAppBaseUrl, apiConstants.chatCookieDomain, ticket.id);
            setChatLoaded(true);
        }
    }, [chatLoaded, ticket?.id, user, authorization.token]); // Added dependency array

    return (
        <PxGridColumn none>
            <div className="card chat-container px-chat" id="ticket-chat" style={{ height: "520px" }}>
                <div className="card-body">
                    <div className="centered-el">
                        <BeatLoader color="#86bacf" loading={true} size={20} speedMultiplier={0.6} />
                    </div>
                </div>
            </div>
        </PxGridColumn>
    );
};

export default ChatClientView;
