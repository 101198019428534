import PxGridCard from "../../pxcore/grid/PxGridCard";
import PxGridRow from "../../pxcore/grid/PxGridRow";
import PxGridColumn from "../../pxcore/grid/PxGridColumn";
import PxFormGroupInfo from "../../pxcore/form/PxFormGroupInfo";
import PxFormInfo from "../../pxcore/form/PxFormInfo";
import moment from "moment/moment";
import React from "react";
import PxCard from "../../pxcore/PxCard";

const statusSolutionMapping = 'solved';

const TicketDetailsCard = (props) => {
    const renderTitle = () => {
        return (
            <>
                Ticketinformationen
            </>
        );
    };

    const hasSolution = () => {
        if(props.ticket !== undefined) {
            if(props.ticket?.statusExternal?.mapping) {
                return true;
            }
        }
        return false;
    }

    return (
        <PxCard title={renderTitle()} cardBodyClass="">
            <div style={{padding:'1rem', width:'100%'}}>
				{props.ticket?.priority?.name ? (
					<PxGridRow>
                    	<PxGridColumn fullscreen>
	                        <PxFormGroupInfo
	                            label="Priorität"
	                            value={(
	                                <span className="badge" style={{
	                                    backgroundColor:"#5b99bc",
	                                    color: "white",
	                                    fontSize: "100%"
	                                }}>
	                                    {props.ticket?.priority?.name}
	                                </span>
	                            )}
	                        />
	                    </PxGridColumn>
	                </PxGridRow>
							) :
					<PxFormInfo label="Priorität" value={'-'} />
				}
                <PxFormInfo label="Titel" value={props.ticket.title} />
                <PxFormInfo label="Beschreibung" value={props.ticket.description} />
                <PxFormInfo label="Typ" value={props.ticket.type?.name} />
				{props.ticket.solution ?
                	(<><PxFormInfo label="Lösung" value={props.ticket.solution} /></>) : <></>
				}
                <PxFormInfo label="Geändert am" value={<><b>{moment(props.ticket.changeDate).format('DD.MM.YYYY')}</b>, {moment(props.ticket.changeDate).format('HH:mm')}</>} />
                {hasSolution() ?
                    (<>
                        <PxGridRow> {props.onSolution} </PxGridRow>
                    </>)
                    : <></>}
            </div>
        </PxCard>
    );
};

export default TicketDetailsCard;

/*
        <PxGridCard title={renderTitle()} large cardBodyClass="">
            <div style={{padding:'1rem'}}>
                <PxGridRow>
                    <PxGridColumn fullscreen>
                        <PxFormGroupInfo
                            label="Priorität:"
                            value={(
                                <span className="badge" style={{
                                    backgroundColor:"#5b99bc",
                                    color: "white",
                                    fontSize: "100%"
                                }}>
                                    {props.ticket?.priority?.name}
                                </span>
                            )}
                        />
                    </PxGridColumn>
                </PxGridRow>
                <PxFormInfo label="Titel" value={props.ticket.title} />
                <PxFormInfo label="Beschreibung" value={props.ticket.description} />
                <PxFormInfo label="Typ" value={props.ticket.type?.name} />
                <PxFormInfo label="Lösung" value={props.ticket.solution} />
                <PxFormInfo label="Geändert am" value={<><b>{moment(props.ticket.changeDate).format('DD.MM.YYYY')}</b>, {moment(props.ticket.changeDate).format('HH:mm')}</>} />
                {hasSolution() ?
                (<>
                    <PxGridRow> {props.onSolution} </PxGridRow>
                </>)
                : <></>}
            </div>
        </PxGridCard>
 */