import { apiConstants, requestPublicUrl } from "./ApiUtils";

const authEndpoint = "auth/login";
/**
 * validates the credentials at the backend, and gets the token as response
 * @param {*} username 
 * @param {*} password 
 * @returns a jwt token if the given credentials are valid
 */
export const authenticateUser = async (username, password) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    let raw = JSON.stringify({
      "username": username,
      "password": password,
      "clientId": apiConstants.clientId
    });
    
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    let response;
    await fetch(requestPublicUrl(authEndpoint), requestOptions)
    .then(response => response.text())
    .then(result => response = JSON.parse(result))
    .catch(error => (
        console.log('error', error)
        ));

    return response? response.token : null;
};