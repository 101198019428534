/** eslint-disable no-template-curly-in-string */
import React, { useContext, useState } from 'react';
import PxMainFrame from "../components/pxcore/PxMainFrame";
import PxHeader from "../components/pxcore/PxHeader";
import PxContent from "../components/pxcore/PxContent";
import PxGridCard from "../components/pxcore/grid/PxGridCard";
import PxButton from "../components/pxcore/PxButton";
import { UserContext } from "../context/UserContextProvider";
import CircularProgress from '@mui/material/CircularProgress';

const LoginScreen = (props) => {
    const { authorize, authorization, clientConfig, azureAuth, initAzure, user, clearData, fetchingUser } = useContext(UserContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState(""); 
    const mainLogo = clientConfig ? 'data:image/jpeg;base64,' + clientConfig.mainImage : null;
    const welcomeText = clientConfig ? clientConfig.loginWelcomeText : null;
    const backgroundImage = clientConfig ? 'data:image/jpeg;base64,' + clientConfig.loginBackgroundImage : null;

    const handleLoginForm = (event) => {
        event.preventDefault();
        authorize(username, password);
    };

    const renderLoginWarning = () => {
        if (authorization?.valid === false) {
            return (
                <span style={{ textAlign: 'center', display: 'block', color: '#ff6262' }}>
                    Anmeldung fehlgeschlagen
                </span>
            );
        }
        return (<></>);
    };

    const handleAzureLogin = () => {
        clearData();
        initAzure();
    };

    function defaultLogin() {
        return (
            <PxMainFrame>
                <PxHeader
                    brand={mainLogo ? <img style={{ width: '10rem' }} className="px-logo" src={mainLogo} alt="Logo" /> : ''}
                />

                <form onSubmit={handleLoginForm}>
                    <PxContent
                        style={{ marginLeft: 0 }}
                        styleContainer={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '8vw'
                        }}
                    >
                        <PxGridCard
                            medium
                            styleBody={{
                                display: 'block',
                                justifyContent: 'center',
                            }}
                            styleCard={{
                                height: "320px",
                                backgroundImage: `url('${backgroundImage}')`,
                                backgroundSize: 'cover',
                                backgroundPositionY: 'center'
                            }}
                        >
                            <div style={{ display: 'block' }} >
                                <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>{welcomeText}</h3>
                                <input
                                    style={{ marginBottom: "1rem", borderColor: "#bbbbbf" }}
                                    value={username}
                                    onChange={(e => setUsername(e.target.value))}
                                    type="text"
                                    placeholder="Benutzername"
                                    name="username"
                                    className="form-control"
                                />
                                <input
                                    style={{ marginBottom: "1rem", borderColor: "#bbbbbf" }}
                                    value={password}
                                    onChange={(e => setPassword(e.target.value))}
                                    type="password"
                                    placeholder="Passwort"
                                    name="password"
                                    className="form-control"
                                />

                                <div style={{ display: 'grid', marginBottom: "1rem" }} >
                                    <PxButton>Anmelden</PxButton>
                                </div>
                                {renderLoginWarning()}
                            </div>
                        </PxGridCard>
                    </PxContent>
                </form>
            </PxMainFrame>
        );
    }

    function azureLogin() {
        return (
            <PxMainFrame>
                <PxHeader
                    brand={mainLogo ? <img style={{ width: '10rem' }} className="px-logo" src={mainLogo} alt="Logo" /> : ''}
                />

                <PxContent
                    style={{ marginLeft: 0 }}
                    styleContainer={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '8vw'
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h1>{azureAuth?.account?.name ? 'Angemeldet als ' + azureAuth?.account?.name : 'Nicht angemeldet'}</h1>
                        {(!azureAuth || !user) && !fetchingUser ? (
                            <PxButton style={{ marginTop: '10px' }} onClick={handleAzureLogin}>Erneut Einloggen</PxButton>
                        ) : null}
                        {"\n"}

                        {fetchingUser ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop:'30px' }}>
                                <CircularProgress />
                                <div>Benutzer wird geladen</div>
                            </div>
                        ) : (<></>)}
                    </div>
                </PxContent>
            </PxMainFrame>
        );
    }

    function showLogin() {
        return clientConfig?.authMethod?.includes('azure') ? azureLogin() : defaultLogin();
    }

    return (
        showLogin()
    );
};

export default LoginScreen;
