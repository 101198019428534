
import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import PxGridCard from "../../../components/pxcore/grid/PxGridCard";
import StatusAvatar from "../../../components/common/StatusAvatar";
import {relativDate} from "../../../components/common/DateUtils";
import moment from "moment/moment";

const TicketHeader = (props) => {

    const parseCreateDate = () => {
        return relativDate(props.ticket?.createDate);
    };

    const parseCreateTime = () => {
        return moment(props.ticket?.createDate).format('HH:mm')
    };

    return( 
        
            <PxGridCard fullscreen cardBodyClass="">
                <PxGridRow style={{alignItems: 'center', marginLeft:'5px'}}>
                    <span style={{marginRight:"10px"}}>
                        <StatusAvatar imageId={props.ticket.avatarId} showSpinner={props.ticket.avatarId !== undefined} avatar={props.avatar} color={props.ticket?.statusExternal?.color || 'grey'}/>
                    </span>

                    <h4 style={{color:'#64757a', fontWeight:'300', marginRight: '5px'}}>
                        ({props.ticket?.number})
                    </h4>

                    <h4 style={{marginRight:"5px"}}>
                        {props.ticket?.title}
                    </h4>

                    <span style={{marginLeft:"auto"}}>
                        <h4 
                            style={
                                {marginRight:"5px", fontWeight:"300"}
                            }
                        >
                            angelegt:
                        </h4>
                    </span>

                    <span style={{marginRight:"5px"}} >
                        <h4>
                            {parseCreateDate()}
                        </h4>
                    </span>

                    <div>
                        <span className="badge" style={{backgroundColor:props.ticket?.statusExternal?.color || 'grey', color:"white", fontSize:"100%", marginBottom:"10px"}} >
                            {props.ticket?.statusExternal?.name}
                        </span>
                    </div>
                </PxGridRow>
            </PxGridCard>
    );
};

export default TicketHeader;