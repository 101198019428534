import './PxStyle.css';
import {
    Link
  } from "react-router-dom";
import { createBrowserHistory } from "history";
import $ from "jquery";

const PxSidebarItem = (props) => {
    
	const appHistory = createBrowserHistory();

    const handleClick= () => {
        if(appHistory.location.hash === `#${props.href}`) {
            window.location.reload();
        }

        // weird hack, check if mobile sidebar is visible
        if ($(".show-sidebar")[0]){
            $("#main-wrapper").toggleClass("show-sidebar");
            $("#main-wrapper").toggleClass("show-menu-mask");
            $(".nav-toggler i").toggleClass("ti-menu");
        }
    }

    return (
        <Link to={props.href} onClick={handleClick}>
        <li class="sidebar-item"> 
            <a class="sidebar-link sidebar-link" aria-expanded="false">

                { props.icon ? (
                    <span className="px-menue-item-icon">
                    {props.icon}
                    </span>
                    )
                : null
                }
           
                <span class="hide-menu px-menue-item">
                    {props.title}
                </span>
            </a>
        </li>
        </Link>
    );
};

export default PxSidebarItem;