import {createAuthHeaders, createRequest, httpMethods, requestPublicUrl} from "./ApiUtils";

const timelineEndpoint 	= "timeline/all";
const entityEndpoint 	= "timeline/entity";

export const fetchTimeline = (token, ticketId, r) => {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let url = requestPublicUrl(timelineEndpoint);
    url = url + `?id=${ticketId}`;
    
    fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => 
    	r(JSON.parse(result))
    ).catch(e => {
		console.log(e);
	});
}

export const fetchEntity = (token, ticketId, entityId) => {
    let headers = createAuthHeaders(token);
    let requestOptions = createRequest(httpMethods.GET, headers);

    let url = requestPublicUrl(entityEndpoint);
    url = url + `?ticketId=${ticketId}`;
    url = url + `&id=${entityId}`;

    let response;

    fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => 
    	 response = JSON.parse(result)
    ).catch(e => {
		console.log(e);
	});
  return response? response.resp : null;
}