import PxGridRow from "../../../components/pxcore/grid/PxGridRow";
import UserAvatarCardView from "../user/UserAvatarCardView";
import DashBoardComponentView from "./DashBoardComponentView";
import DashboardTableCard from "../dashboard/DashboardTableCard"
import { useContext } from "react";
import { UserContext } from "../../../context/UserContextProvider";
import { TicketSearchContext } from "../../../context/TicketSearchContext";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import TicketScreen from "../ticket/TicketScreen";
import TicketTableCard from "../ticket/TicketTableCard";
import TicketDetailsScreen from "../ticket/TicketDetailsScreen";

import {
    Switch,
    Route,
    useRouteMatch
  } from "react-router-dom";

    const DashBoardScreen = (props) => {
    const {user, getAvatar, fetchUser, clientConfig, dashboardConfig} = useContext(UserContext);
    const {fetchTicketAvatar, fetchTicket} = useContext(TicketSearchContext);
	const dashboardWelcomeText = clientConfig.dashboardWelcomeText;
    const history = useHistory();
    let {path} = useRouteMatch();

    useEffect(() => {
        fetchUser();
    }, [])

    function handleClick(mapping) {
        history.push(`filter/${mapping}`);      
    }

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <PxGridRow>
                        <div className="col-md-6 align-self-center" style={{paddingBottom:"20px"}}>
                        <h3 className="page-title">{dashboardWelcomeText}</h3>
                        <span lcass="breadcrumb-item">KUNDENPORTAL / </span>
                        <span className="breadcrumb-item active"> DASHBOARD</span>
                    
                        </div>
                    </PxGridRow>
        
                    <PxGridRow className="dashboard"> 
                    {
                        dashboardConfig? dashboardConfig
                        .sort((a, b) => a.sort > b.sort ? 1 : -1)
                        .filter(config => config?.type === "key_figure" && user.showTickets)
                        .map((data, index) => (
                            <DashBoardComponentView key={index} config={data} onClick = {() => handleClick(JSON.parse(data.config).ticket_mapping)}/> 
                        )): null
                        
                    
                    }
                    </PxGridRow>
        
                    <PxGridRow>
                    {   user.showTickets ? 
                        (dashboardConfig?.filter(config => config?.type === "ticket_list").length !== 0) ?
                        dashboardConfig?.filter(config => config?.type === "ticket_list")
                        .map((data, index) => (
                            <DashboardTableCard key={index} config={data}/>
                        )) : <TicketTableCard width/> : ''
                    }
                    <UserAvatarCardView user={user} getAvatar={getAvatar}/>
                    </PxGridRow>
                </Route>
                <Route path={`${path}filter/:mapping/:ticketId`}>
                    <TicketDetailsScreen fetchAvatar={fetchTicketAvatar} fetchTicket={fetchTicket}/>
                </Route>
                <Route path={`${path}filter/:mapping`}>
                    <TicketTableCard/>
                </Route>
                <Route path={`${path}children/:mapping/tickets/:ticketId`}>
                    <TicketDetailsScreen fetchAvatar={fetchTicketAvatar} fetchTicket={fetchTicket}/>
                </Route>
                <Route path={`${path}children/:mapping/:ticketId`}>
                    <TicketTableCard/>
                </Route>
            </Switch>
        </>
    );
    

};

export default DashBoardScreen;
// <TotalNumberDiagram data={{count: 12}} config={{text:"Offene Tickets", color:"#252b4a", icon:(<TicketIcon  color="white" outlined />)}} />